<template>
  <div class="hybrid-classroom" style="margin-bottom: 5rem">
    <div class="about-top">
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-inner first_img">
          <div class="w-100 h-100 position-relative">
            <img src="https://artive.com.bd/media/images/conference_hero_MuWaoH4.webp" alt="..."
                 class="d-none d-md-block w-100 h-100" style="object-fit: cover;">
            <img src="https://artive.com.bd/media/images/conference_heroMOB.webp"
                 class="d-block d-md-none w-100 h-100" alt="..." style="object-fit: cover;">
            <div class="carousel-caption carousel_caption_one">
              <h1 class="about-top-head">SMART CONFERENCE
              </h1>
              <p class="about-top-descrip">Advanced technology for seamless communication, enabling effective
                collaboration with features like high-quality video, clear audio, and easy content sharing in
                meetings.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="smart_classroom">
        <div class="w-100 w-lg-50">
          <h2 class="smart_modern">Smart Conference, Smarter Decisions</h2>
        </div>
        <div class="w-100 w-lg-50">
          <p class="smart_modern_description">It includes features like high-definition video conferencing,
            crystal-clear audio, and interactive displays that allow for real-time collaboration, whether participants
            are in the same room or across the globe. Smart conference solutions also offer tools for easy content
            sharing, remote participation, and efficient meeting management, ensuring that every meeting is productive
            and engaging.</p>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/conference_page1.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="desc" style="padding: 80px;">
            <h2 class="more_edu_head">Ultra 4K Video</h2>
            <p class="more_edu_descrip">13 Megapixel Camera provides a high-quality video conference experience with
              delicate and vivid picture, and clear and consistent display effects in light and dark environments.</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 wireless">
          <div class="desc" style="padding: 80px;">
            <h2 class="more_edu_head">Wireless Presentation</h2>
            <p class="more_edu_descrip">Allow Participant to share presentation wirelessly and smooth writing Facility
              with Graphical presentation make meetings More interactive.</p>
          </div>
        </div>
        <div class="w-100 w-lg-50 third_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Smart_Conference_3.webp" alt="">
        </div>

      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Smart_Conference_4.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="desc" style="padding: 80px;">
            <h2 class="more_edu_head">Intelligent Speakerphone</h2>
            <p class="more_edu_descrip">Advanced conference speakerphone ensures steady interaction of every participant
              covering 10M long distance pickup.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SmartConference"
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.last img {

}
.carousel-inner {
  height: 810px;
}
.about-top-head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 76.8px */
  text-transform: capitalize;
}


.carousel_caption_one {
  position: absolute;
  right: 6%;
  bottom: 16.25rem;
  left: 6%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
  width: 500px;
  margin-bottom: 15px;
}

.about-top-descrip {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.smart_classroom {
  padding: 80px;
  gap: 80px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
}

.classroom {
  background: #F7F7F7;
}

.smart_modern {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  width: 500px;
}

.smart_modern_description {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.more_edu_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.more_edu_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.class_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_ul li {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 228.571% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .last img {
    width: 100%;
    padding: 1.25rem;
  }
  .carousel-inner {
    height: 600px;
  }

  .carousel_caption_one {
    position: absolute;
    right: 6%;
    bottom: 3rem;
    left: 6%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
    width: 90%;
    display: none;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }

  .third_img {
    height: 400px !important;
  }

  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
  .desc{
    padding: 20px !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .last img {
    width: 100%;
    padding: 1.25rem;
  }

  .carousel_caption_one {
    position: absolute;
    right: 6%;
    left: 6%;
    top: 35%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }

  .second_img {
    height: 350px !important;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }


  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
  .desc{
    padding: 40px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
</style>