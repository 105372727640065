<template>
  <div class="w-100 part_two_bottom bg-white position-relative " style="z-index: 0">
    <div class="d-flex flex-column-reverse flex-lg-row align-items-center bg-white">
      <!-- Left side: Swiper Slider -->
      <div class="left-side part_two_bottom_text ps-0" :id="this.id" style="padding-right: 5rem;">
        <swiper
            :pagination="false"
            :loop="true"
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }"
            :modules="modules"
            class="mySwiper"
        >
          <!-- Swiper Slides -->
          <swiper-slide
              v-for="(slide, index) in slides"
              :key="index"
              class="d-flex flex-column align-items-start"
              style="text-align: justify;"
          >
            <h2>{{ slide.title }}</h2>
            <p>{{ slide.description }}</p>
          </swiper-slide>
          <!-- Swiper Pagination -->
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
      <!-- Right side: Image -->
      <div class="right-side">
        <swiper
            :pagination="false"
            :loop="true"
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }"
            :modules="modules"
            class="imageSwiper"
        >
          <!-- Image Slides (corresponding to text slides) -->
          <swiper-slide v-for="(image, index) in images" :key="index">
            <img :src="image.src" :alt="image.alt"/>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  name: "ProductSubfourslide",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        slides: [
          {
            title: "Contrast Ratio 1200:1 (Typ.)",
            description: "This ratio measures the difference between the darkest black and the brightest white that the display can produce. A higher contrast ratio means deeper blacks and brighter whites, resulting in more vibrant and lifelike images. With a typical contrast ratio of 1200:1, this interactive flat panel ensures excellent image depth and clarity, enhancing the viewing experience for all types of content."
          },
          {
            title: "Ultra HD Resolution",
            description: "The 4K Ultra HD resolution provides exceptional clarity and detail, making images and text sharp, and enhancing the visual experience for presentations and media."
          },
          {
            title: "Touchscreen Capabilities",
            description: "The touchscreen feature allows users to interact with the display effortlessly, enabling smooth and responsive touch inputs for better usability and collaboration."
          }
        ],
        images: [
          {
            src: "https://artive.com.bd/media/images/WEB_Home_Education1.webp",
            alt: "Image 1"
          },
          {
            src: "https://artive.com.bd/media/images/WEB_Home_Education1.webp",
            alt: "Image 2"
          },
          {
            src: "https://artive.com.bd/media/images/WEB_Home_Education1.webp",
            alt: "Image 3"
          }
        ]
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    slides() {
      return JSON.parse(this.content).slides;
    },
    images() {
      return JSON.parse(this.content).images;
    }
  }
}
</script>

<style scoped>


.part_two_bottom {
  padding: 0 60px;
}

.left-side, .right-side {
  width: 50% !important;
}

.right-side img {
  width: 100%;
}

.part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.part_two_bottom_text p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  margin-right: 40px !important;
  width: 15px !important;
  height: 15px !important;
}

.two-img {
  height: 650px;
}

@media only screen and (max-width: 768px) {
  .right-side, .left-side {
    width: 100% !important;
    padding-right: 0 !important;
  }
  .right-side img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  .part_two_bottom {
    padding: 20px;
    margin-bottom: 20px;
  }

  .part_two_bottom_text {
    padding-right: 0px !important;
    margin-top: 20px;
  }
  .part_two_bottom_text h2 {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
}
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_two_bottom {
    padding: 20px;
    margin-bottom: 50px;
  }

  .part_two_bottom_text {
    padding-right: 0px !important;
    margin-top: 30px;
  }
  .part_two_bottom_text h2 {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}
}

</style>