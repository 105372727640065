<template>
  <div class="w-100 part_two_top " :id="this.id">
    <div class="w-100">
      <div class="m-auto" style="max-width: 800px">
        <h2>{{ contentData.title }}</h2>
      <p class="d-none d-lg-block">{{ contentData.text }}</p>
      </div>
    </div>
    <div class="part_two_top_text content d-none d-md-block" :style="{ backgroundImage: `url(${contentData.image})` }"></div>
    <div class="part_two_top_text content d-block d-md-none " :style="{ backgroundImage: `url(${contentData.mobimage})` }">
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubtwo",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        image: "./Artives-Interactive-Details-image/enjoy.png",
        mobimage: "./Artives-Interactive-Details-image/enjoy.png",
        alt: "4K Display",
        title: "Enjoy Crystal Clear 4K Display",
        text: "Our interactive flat panels boast 4K display technology, ensuring everything you showcase appears in stunning clarity. From presentations to videos, every detail shines brightly, making your meetings and lessons more engaging than ever."
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    }
  }
}
</script>

<style scoped>
/* Add any specific styling needed for the component here */
</style>

<style scoped>

.part_two_top_text {
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 100vh;

}


.part_two_top h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 76.8px */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_two_top p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

.content {
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .part_two_top h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    padding: 20px 0;
  }

  .part_two_top_text {
    height: 500px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_two_top h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    padding: 50px 0;
  }

  .part_two_top_text {
    height: 500px;
    margin-bottom: 50px;
  }
}
</style>