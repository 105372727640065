<template>
  <div class="w-100 part_three_mid d-flex flex-column-reverse flex-lg-row " :id="this.id" style="gap: 5rem; padding: 5rem;">
    <div class="w-100 w-lg-50">
      <div class="card h-100 d-grid" style="width: 100%; box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);">
        <img :src="leftCard.image" :alt="leftCard.alt"  class="card-img-top">
        <div class="card-body card_body d-flex flex-column justify-content-end">
          <h2 class="card-title">{{ leftCard.title }}</h2>
          <p class="card-text">{{ leftCard.text }}</p>
        </div>
      </div>
    </div>
    <div class="w-100 w-lg-50">
      <div class="card h-100" style="width: 100%; box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);">
        <img :src="rightCard.image" :alt="rightCard.alt" class="card-img-top">
        <div class="card-body card_body">
          <h2 class="card-title">{{ rightCard.title }}</h2>
          <p class="card-text">{{ rightCard.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubsix",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        leftCard: {
          image: "./Artives-Interactive-Details-image/image 119.png",
          alt: "Wide Field of View",
          title: "Wide Field of View",
          text: "The camera's wide-angle viewing angle of 92 degrees with a 7.2mm lens diameter is ideal for capturing a wide field of view in video calls and meetings, ensuring more participants or background details are visible in the frame."
        },
        rightCard: {
          image: "./Artives-Interactive-Details-image/Frame 1000001815.png",
          alt: "Speaker Tracking",
          title: "Speaker Tracking",
          text: "The camera's autofocus feature ensures sharp and clear images and videos, regardless of the user's proximity, making it ideal for video calls, presentations, and high-quality photo and video capture without manual adjustment."
        }
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    },
    leftCard() {
      return this.contentData.leftCard || {
        image: '',
        alt: '',
        title: 'Default Title',
        text: 'Default Text'
      };
    },
    rightCard() {
      return this.contentData.rightCard || {
        image: '',
        alt: '',
        title: 'Default Title',
        text: 'Default Text'
      };
    }
  }
}
</script>

<style scoped>
/* Add any specific styling needed for the component here */
</style>

<style scoped>
.card_body {
  padding: 40px;
}

.card_body h2 {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.card_body p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .flex-column-reverse {
    gap: 40px !important;
    padding: 20px !important;
  }

  .card_body {
    padding: 16px;
  }

  .card_body h2 {
    color: #1E1E1E;
    font-size: 24px;
    margin-bottom: 8px;
}
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .flex-column-reverse {
    gap: 40px !important;
    padding: 20px !important;
  }

  .card_body {
    padding: 20px;
  }

  .card_body h2 {
    color: #1E1E1E;
    font-size: 28px;
  }
}
</style>