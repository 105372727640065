<template>
  <div class="w-100 part_one_top" :id="this.id">
    <div class="part_one_top_text">
      <h2>{{ title }}</h2>
      <p class="d-none d-lg-block">{{ description }}</p>
    </div>
    <!--    <img class="w-100"  :src="imageUrl" alt="">-->
    <video class="w-100 d-md-block d-none" loop autoplay muted>
      <source :src="imageUrl" type="video/webm">
      Your browser does not support the video tag.
    </video>
    <video class="w-100 d-md-none d-block" loop autoplay muted>
      <source :src="mainimageUrl" type="video/webm">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: 'PartOneTop',
  props: {
    title: {
      type: String,
      default: 'Seamlessly Simple All-in-One Interactive Flat Panel'
    },
    description: {
      type: String,
      default: 'Our interactive flat panel has both Windows, Android 11, Mac, Linux and Chrome OS giving you the best of both worlds. With a fast 2.2 GHz CPU, 8 GB RAM, and 128 GB storage, it handles everything smoothly. Switch between Windows for work tasks and Android for apps easily. Perfect for meetings, teaching, and presentations.'
    },
    imageUrl: {
      type: String,
      default: './Artives-Interactive-Details-image/Group 1000001830.png'
    },
    mainimageUrl: {
      type: String,
      default: './Artives-Interactive-Details-image/Group 1000001830.png'
    },
    imageHeight: {
      type: [String, Number],
      default: 700
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.part_one_top {
  margin-top: 50px;
  text-align: center;
}

.part_one_top_text {
  max-width: 800px;
  margin: auto;
}

.part_one_top_text h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 76.8px */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_one_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

@media only screen and (max-width: 576px) {
  .part_one_top_text h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .part_one_top_text p {
    color: #1E1E1E;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .part_one_top {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
  }


}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_one_top_text h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .part_one_top_text p {
    color: #1E1E1E;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .part_one_top {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
  }
}
</style>