<template>
  <div class="hybrid-classroom" style="margin-bottom: 5rem">
    <div class="about-top">
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-inner first_img">
          <div class="w-100 h-100 position-relative">
            <img src="https://artive.com.bd/media/images/WEB_Audio_Visual.webp" alt="..."
                 class="d-none d-md-block w-100 h-100" style="object-fit: cover;">
            <img src="https://artive.com.bd/media/images/MOB_Audio_Visual_1_FCmEy4j.webp"
                 class="d-block d-md-none w-100 h-100" alt="..." style="object-fit: cover;">
            <div class="carousel-caption carousel_caption_one">
              <h1 class="about-top-head">AUDIO-VISUAL
              </h1>
              <p class="about-top-descrip">Superior stability and durability, crafted with high-quality materials for
                long-lasting performance.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="smart_classroom">
        <div class="w-100 w-lg-50">
          <h2 class="smart_modern">Next-Level Audio-Visual
            Technology</h2>
        </div>
        <div class="w-100 w-lg-50">
          <p class="smart_modern_description">This technology includes high-definition displays, surround sound systems,
            and intelligent controls, all designed to create a more immersive and interactive experience. It can be used
            in various settings, from corporate meeting rooms to educational institutions, ensuring clear communication,
            dynamic presentations, and enhanced engagement.</p>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Audio_Visual_2.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="desc" style="padding: 80px;">
            <h3 class="more_edu_head">Split Screen Play</h3>
            <p class="more_edu_descrip">Free typesetting in a variety of split screen modes, and video and pictures can
              be
              shown on one machine in multi screen rotation</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 wireless">
          <div class="desc" style="padding: 80px;">
            <h3 class="more_edu_head">Scroll Caption</h3>
            <p class="more_edu_descrip">create engaging, interactive, and adaptable learning environments using
              technology
              like SMART Boards to enhance lessons, foster collaboration, and make learning more effective.</p>
          </div>
        </div>
        <div class="w-100 w-lg-50 third_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Audio_Visual_3.webp" alt="">
        </div>

      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Audio_Visual_4.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="desc" style="padding: 80px;">
            <h3 class="more_edu_head">Auto Play</h3>
            <p class="more_edu_descrip">Automatically play pictures, videos and background music of U disk,SD card and
              mobile hard disk after booting.</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 wireless">
          <div class="desc" style="padding: 80px;">
            <h3 class="more_edu_head">Remote Management</h3>
            <p class="more_edu_descrip">create engaging, interactive, and adaptable learning environments using
              technology
              like SMART Boards to enhance lessons, foster collaboration, and make learning more effective.</p>
          </div>

        </div>
        <div class="w-100 w-lg-50 third_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Audio_Visual_5.webp" alt="">
        </div>

      </div>
      <div class="text-center last" style="margin: 5rem 0;">
        <img src="https://artive.com.bd/media/images/WEB_Audio_Visual_6.webp" alt="No Image Found">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PaperlessClassroom"
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.last img {

}
.carousel-inner {
  height: 810px;
}

.about-top-head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 76.8px */
  text-transform: capitalize;
}


.carousel_caption_one {
  position: absolute;
  right: 6%;
  bottom: 16.25rem;
  left: 6%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
  width: 500px;
  margin-bottom: 15px;
}

.about-top-descrip {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.smart_classroom {
  padding: 80px;
  gap: 80px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
}

.classroom {
  background: #F7F7F7;
}

.smart_modern {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  width: 500px;
}

.smart_modern_description {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.more_edu_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.more_edu_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.class_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_ul li {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 228.571% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .carousel-inner {
    height: 600px;
  }

  .last img {
    width: 100%;
    padding: 1.25rem;
  }

  .carousel_caption_one {
    position: absolute;
    right: 6%;
    bottom: 3rem;
    left: 6%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
    width: 90%;
    display: none;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }

  .third_img {
    height: 400px !important;
  }

  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
  .desc{
    padding: 20px !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .last img {
    width: 100%;
    padding: 1.25rem;
  }

  .carousel_caption_one {
    position: absolute;
    right: 6%;
    left: 6%;
    top: 35%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }

  .second_img {
    height: 350px !important;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }


  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
  .desc{
    padding: 40px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
</style>