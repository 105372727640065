<template>
  <div class="w-100 part_one_top" :id="this.id">
    <div class="part_one_top_text mb-5">
      <h2>{{ contentData.title }}</h2>
      <p class="d-none d-lg-block">{{ contentData.description }}</p>
    </div>
    <img class="w-100 d-none d-md-block" :src="contentData.image.src" :alt="contentData.image.alt">
    <img class="w-100 d-block d-md-none" :src="contentData.mobimage.src" :alt="contentData.mobimage.alt">
  </div>
</template>

<script>
export default {
  name: "ProductSubfive",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        title: "Wide-Angle Autofocus Built-In Camera",
        description: "The panel features a 12-megapixel camera with a wide-angle lens, autofocus function, and high resolution, enhancing communication and capturing detailed visuals for video conferences and online classes.",
        image: {
          src: "./Artives-Interactive-Details-image/Rectangle 4249.png",
          height: "700",
          alt: "Wide-Angle Autofocus Camera"
        },
        mobimage: {
          src: "./Artives-Interactive-Details-image/Rectangle 4249.png",
          height: "400",
          alt: "Wide-Angle Autofocus Camera"
        }
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    }
  }
}
</script>

<style scoped>
.part_one_top {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 6.25rem;
}

.part_one_top_text {
  max-width: 800px;
  margin: auto;
}

.part_one_top_text h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 76.8px */
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
}

.part_one_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}
@media only screen and (max-width: 576px) {
  .part_one_top_text h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 24px;

  }

  .part_one_top {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .part_one_top_text {
    max-width: 800px;
    margin-bottom: 1rem !important;
    padding: 0 20px;

  }

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_one_top_text h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 35px;

  }
  .part_one_top_text {
    max-width: 800px;
    margin: auto;
    padding: 0 40px;
  }

}
</style>