<template>
  <div class="w-100 part_one_bottom" :id="this.id" style="padding: 5rem;">
    <div class="w-100 d-flex justify-content-between img_gap align-items-center" style="gap: 80px">
      <div class="w-50 one_bottom_left">
        <div class="same_bottom">
          <img class="w-100 h-100" :src="leftImage.src" :alt="leftImage.alt">
        </div>
      </div>
      <div class="w-50 one_bottom_right">
        <div class="same_bottom">
          <img class="w-100 h-100" :src="rightImage.src" :alt="rightImage.alt">
        </div>
      </div>
    </div>
    <div class="w-100 bottom_text d-flex align-items-center justify-content-center">
      <h3 class="w-50 text-center">{{ leftText }}</h3>
      <h3 class="w-50 text-center">{{ rightText }}</h3>

    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubone",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        leftImage: {
          src: "./Artives-Interactive-Details-image/Rectangle 4244.png",
          alt: "CPU Image"
        },
        leftText: "CPU (A73 2.28GHz)",
        rightImage: {
          src: "./Artives-Interactive-Details-image/Rectangle 4245.png",
          alt: "RAM & ROM Image"
        },
        rightText: "8 GB RAM, 128 GB ROM"
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      console.log(this.content)
      return JSON.parse(this.content);
    },
    leftImage() {
      return this.contentData.leftImage || { src: '', alt: '' };
    },
    leftText() {
      return this.contentData.leftText || '';
    },
    rightImage() {
      return this.contentData.rightImage || { src: '', alt: '' };
    },
    rightText() {
      return this.contentData.rightText || '';
    }
  }
}
</script>

<style scoped>
/* Add any specific styling needed for the component here */
</style>

<style scoped>
.part_one_bottom {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 80px;

  background: #F7F7F7;
}

.one_bottom_left,
.one_bottom_right {
  text-align: center;

}

.one_bottom_left img,
.one_bottom_right img {
  border-radius: 10px;
}

.one_bottom_left h3,
.one_bottom_right h3 {
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
  /* 30.72px */
}
 .bottom_text h3{
    font-size: 24px;
  }

@media only screen and (max-width: 576px) {
  .part_one_bottom {
    display: flex;
    gap: 20px;
    padding: 20px !important;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;
  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 128%;
  }

  .same_bottom {

    margin-bottom: 20px;
  }
  .bottom_text{
    gap: 20px !important;
  }
  .img_gap{
    gap: 20px !important;
  }
  .bottom_text h3{
    font-size: 14px;
  }

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_one_bottom {
    display: flex;
    gap: 20px;
    padding: 20px !important;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;
  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 128%;
  }

  .same_bottom {

    margin-bottom: 20px;
  }
   .bottom_text{
    gap: 20px !important;
  }
  .img_gap{
    gap: 20px !important;
  }
   .bottom_text h3{
    font-size: 18px;
  }
}
</style>