<template>
  <div class="high_quality w-100 overflow-hidden">
    <div class="logos">
      <div class="logos-slide">
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">Smart Techonology</h3>
        </div>
      </div>
      <div class="logos-slide">
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1E1E1E"/>
                        </svg>
                    </span>
          <h3 class="high">High Quality</h3>
        </div>
      </div>
    </div>
    <div class="logos_two">
      <div class="logos-slide_two">
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">Smart Techonology</h3>
        </div>
      </div>
      <div class="logos-slide_two">
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">Smart Techonology</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">AV Solution</h3>
        </div>
        <div class="d-flex align-items-center white_line_scroll" style="gap: 40px;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M26.8489 8.18831L16.681 10.5506L13.9879 0.465055L10.9445 10.4505L0.865242 7.73477L8.00045 15.3635L0.603373 22.7372L10.7713 20.3749L13.4644 30.4605L16.5078 20.475L26.587 23.1908L19.4532 15.5634L26.8489 8.18831Z"
                                  fill="#1AE5FE"/>
                        </svg>
                    </span>
          <h3 class="high-2 ">High Quality</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HighQuality"
}
</script>

<style scoped>
.logos {
  height: 90px;
  background: #F6feff;
  overflow: hidden;
  display: flex;
  gap: 0px;
}

.logos_two {
  height: 90px;
  background: #313131;
  overflow: hidden;
  display: flex;
  gap: 0px;
  transform: translateY(-42px) rotate(-2deg);
}

.logos-slide {
  white-space: nowrap;
  display: flex;
  gap: 40px;
  animation: 40s slide infinite linear;
}

.logos-slide_two {
  white-space: nowrap;
  display: flex;
  gap: 40px;
  animation: 40s slideTwo infinite linear;
}

.high-2 {
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 48px */
}

.white_line_scroll {
  display: flex;
}

.high_quality {
  padding: 80px 0;
  padding-top: 120px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideTwo {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 576px) {
  .high {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }

  .high-2 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 48px */
  }

  .logos {
    height: 50px;
    background: #F6feff;
    overflow: hidden;
    display: flex;
    gap: 0px;
  }

  .logos_two {
    height: 50px;
    background: #313131;
    overflow: hidden;
    display: flex;
    gap: 0px;
    transform: translateY(-16px) rotate(-3deg);
  }

  .logos-slide {
    white-space: nowrap;
    display: flex;
    gap: 20px;
    animation: 40s slide infinite linear;
  }

  .logos-slide_two {
    white-space: nowrap;
    display: flex;
    gap: 20px;
    animation: 40s slideTwo infinite linear;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .high {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }

  .high-2 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 48px */
  }

  .logos {
    height: 50px;
    background: #F6feff;
    overflow: hidden;
    display: flex;
    gap: 0px;
  }

  .logos_two {
    height: 50px;
    background: #313131;
    overflow: hidden;
    display: flex;
    gap: 0px;
    transform: translateY(-16px) rotate(-3deg);
  }

  .logos-slide {
    white-space: nowrap;
    display: flex;
    gap: 20px;
    animation: 40s slide infinite linear;
  }

  .logos-slide_two {
    white-space: nowrap;
    display: flex;
    gap: 20px;
    animation: 40s slideTwo infinite linear;
  }
}

</style>