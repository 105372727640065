<template>
  <div class="w-100 part_two_bottom bg-white" :id="this.id">
    <div class="d-flex flex-column flex-lg-row align-items-center bg-white">
      <div class="w-100 w-lg-50">
        <img :src="contentData.image" :alt="contentData.alt" class="w-100 d-none d-md-block">
        <img :src="contentData.mobimage" :alt="contentData.alt" class="w-100 d-block d-md-none">
      </div>
      <div class="w-100 w-lg-50 part_two_bottom_text">
        <div class="disc" style="padding-left: 5rem;">
          <h2>{{ contentData.title }}</h2>
          <p >{{ contentData.text }}</p>
          <div>
            <img :src="contentData.icon" :alt="contentData.iconAlt">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubthree",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        image: "./Artives-Interactive-Details-image/Frame 1000001789.png",
        mobimage: "./Artives-Interactive-Details-image/Frame 1000001789.png",
        alt: "Product Image",
        title: "Precise Visuals, More Detail",
        text: "This measurement indicates the distance between the centers of two adjacent pixels. A smaller pixel pitch provides finer detail and higher resolution, resulting in sharper and clearer images. With a pixel pitch of 0.5622mm × 0.5622mm, this interactive flat panel delivers precise visuals, making it ideal for presentations, detailed graphics, and multimedia content.",
        icon: "./Artives-Interactive-Details-image/icon-three.png",
        iconAlt: "Icon"
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    }
  }
}
</script>

<style scoped>
/* Add any specific styling needed for the component here */
</style>

<style scoped>
.part_two_bottom {
  padding: 0 60px;
}

.part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.part_two_bottom_text p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .part_two_bottom {
    padding: 0 20px!important;

  }
  .disc{
    padding-left: 0 !important;
  }
  .part_two_bottom_text {
    padding-left: 0rem;
    margin-top: 20px;
  }

  .part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_two_bottom {
    padding: 0 20px !important;
  }
  .disc{
    padding-left: 0 !important;
  }
  .part_two_bottom_text {
    padding-left: 0rem;
     margin-top: 30px;
  }
  .part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
}
</style>