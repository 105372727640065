<template>
  <div class="Artiv_footer">
    <div class="footer w-100 d-flex">
      <div class="footer-left ">
        <div class="footer_text_top footer_text_one d-flex justify-content-between">
          <div class="sec_1">
            <svg xmlns="http://www.w3.org/2000/svg" width="134" height="35" viewBox="0 0 134 35"
                 fill="none">
              <mask id="mask0_236_2067" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0"
                    width="134" height="35">
                <path d="M133.628 0.5H0V34.5H133.628V0.5Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_236_2067)">
                <path
                    d="M45.1875 9.21381H46.1759V16.1625C46.8638 13.8781 48.0577 12.1236 49.749 10.9059C51.4411 9.68822 53.5199 9.07227 55.994 9.07227V10.0599H55.7093C52.7371 10.0599 50.4053 11.0561 48.7132 13.04C47.0219 15.0246 46.1759 17.7755 46.1759 21.2854V34.2971H45.1875V9.21381Z"
                    fill="white"/>
                <path
                    d="M59.043 9.21398H63.8812V3.62533L64.8696 3.43555V9.21398H74.7826V10.1549H64.8696V28.2816C64.8696 31.7599 66.5767 33.4987 69.9918 33.4987C71.3992 33.4987 72.9956 33.1824 74.7826 32.5585V33.4987C73.1221 34.1233 71.5099 34.4396 69.9443 34.4396C68.0316 34.4396 66.5451 33.9256 65.4856 32.8898C64.4189 31.8548 63.8892 30.3999 63.8892 28.5189V10.1549H59.0509V9.21398H59.043Z"
                    fill="white"/>
                <path
                    d="M80.082 2.49567C80.082 2.14776 80.2086 1.8552 80.4616 1.60218C80.7146 1.34915 81.0071 1.22266 81.355 1.22266C81.7029 1.22266 81.9947 1.34915 82.2478 1.60218C82.5008 1.8552 82.6194 2.14776 82.6194 2.49567C82.6194 2.84278 82.4929 3.13534 82.2478 3.38836C81.9947 3.64139 81.7029 3.76791 81.355 3.76791C81.0071 3.76791 80.7146 3.64139 80.4616 3.38836C80.2086 3.13534 80.082 2.84278 80.082 2.49567ZM81.8682 9.21423V34.2975H80.8806V9.21423H81.8682Z"
                    fill="white"/>
                <path
                    d="M86.5135 9.21484L97.407 33.2623L108.308 9.21484H109.438L97.8814 34.2981H96.9404L85.3828 9.21484H86.5135Z"
                    fill="white"/>
                <path
                    d="M113.667 12.5498C115.825 10.2338 118.6 9.07227 121.983 9.07227C125.366 9.07227 128.071 10.2417 130.3 12.5735C132.521 14.906 133.636 17.8783 133.636 21.4752V21.7598H111.375C111.375 25.3014 112.41 28.1391 114.474 30.2811C116.537 32.4239 119.28 33.4985 122.695 33.4985C126.11 33.4985 129.035 32.2658 131.011 29.7909L131.715 30.3523C129.62 33.0794 126.608 34.4394 122.695 34.4394C118.782 34.4394 115.983 33.2771 113.746 30.9612C111.51 28.6452 110.387 25.5781 110.387 21.7519C110.387 17.9257 111.486 14.8902 113.675 12.5418L113.667 12.5498ZM111.367 20.8189H132.687C132.529 17.7201 131.446 15.1433 129.422 13.0953C127.399 11.0482 124.924 10.0203 121.975 10.0203C119.027 10.0203 116.529 11.0166 114.553 13.0005C112.576 14.993 111.517 17.5936 111.359 20.8189H111.367Z"
                    fill="white"/>
                <path
                    d="M23.2821 6.23414L7.08462 34.2976H1.23503C0.286193 34.2976 -0.298928 33.2776 0.167584 32.456L18.2311 1.17527C18.7047 0.360848 19.8828 0.360848 20.3573 1.17527L23.2821 6.24205V6.23414Z"
                    fill="#1BE5FE"/>
                <path d="M28.0642 14.5194L16.641 34.298H10.2695L24.8785 9.00195L28.0642 14.5194Z"
                      fill="#1BE5FE"/>
                <path d="M32.8555 22.8106L26.2232 34.2971H19.8359L29.6619 17.2773L32.8555 22.8106Z"
                      fill="#1BE5FE"/>
                <path
                    d="M37.3628 34.2972H29.4258L34.4617 25.5781L38.4302 32.4556C38.9039 33.2692 38.3116 34.2972 37.3628 34.2972Z"
                    fill="#1BE5FE"/>
              </g>
            </svg>
            <p>We provide online and integrated audio-visual solutions for schools, government, and
              businesses,
              enhancing communication and collaboration.</p>
            <div class="social_icon">
              <a :href="siteData.x_url">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                  <path
                      d="M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 7.21573 23.2843 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5Z"
                      fill="#FF0000"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M23.4754 9.78356C23.7747 10.0829 23.9904 10.4554 24.1009 10.864C24.7376 13.4268 24.5905 17.4743 24.1133 20.136C24.0028 20.5446 23.7871 20.9171 23.4877 21.2164C23.1884 21.5157 22.8159 21.7314 22.4073 21.842C20.9114 22.25 14.8908 22.25 14.8908 22.25C14.8908 22.25 8.87017 22.25 7.3743 21.842C6.9657 21.7314 6.59317 21.5157 6.29385 21.2164C5.99452 20.9171 5.77882 20.5446 5.66827 20.136C5.02785 17.5843 5.20342 13.5343 5.6559 10.8764C5.76645 10.4677 5.98215 10.0952 6.28147 9.79586C6.5808 9.49654 6.95332 9.28091 7.36192 9.17029C8.8578 8.76236 14.8784 8.74999 14.8784 8.74999C14.8784 8.74999 20.899 8.74999 22.3949 9.15799C22.8035 9.26854 23.176 9.48423 23.4754 9.78356ZM17.9567 15.5L12.9622 18.3928V12.6071L17.9567 15.5Z"
                        fill="white"/>
                </svg>
              </a>
              <a :href="siteData.facebook_url">
                <img src="./Artives-Footer-image/Facebook.png" alt="">
              </a>
              <a :href="siteData.linkedin_url">
                <img src="./Artives-Footer-image/Facebook (1).png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-mid" style="gap: 5rem; margin: auto 0;">
        <div class="sec_">
          <h2>Product</h2>
          <div v-for="(category, index) in categoriesData" :key="index">
            <a :href="`/products/${category.title}/#product-categories`">{{ category.title }}</a>
          </div>


        </div>
        <div class="sec_">
          <h2>Solution</h2>
          <a href="/solutions/?label=Smart%20Classroom%20Solution">Education</a>
          <a href="/solutions/?label=Audio%20Visual">Audio</a>
          <a href="/solutions/?label=Smart%20Conference">Conference</a>
        </div>
        <div class="sec_">
          <h2>Support</h2>
          <a href="/contact-us/">Contact Us</a>
        </div>
      </div>
      <div class="footer-right d-none d-xl-block" style="margin: auto 0;">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="sec_">
            <h2>Join Our Newsletter</h2>
            <p>To stay up to date on all the latest news and offer from us. </p>
            <form action="" method="">
              <div class="join_form rounded-pill">
                <input type="email" v-model="email" placeholder="Enter your email" required>
                <button @click.prevent="submitForm">JOIN</button>
              </div>
              <p v-if="newsletterShow" class="msg-newsletter">Join successful!</p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-right d-xl-none" style="margin: auto 0;">
      <div class="d-flex align-items-center justify-content-center w-100">
        <div class="sec_">
          <h2>Join Our Newsletter</h2>
          <p>To stay up to date on all the latest news and offer from us. </p>
          <form action="" method="">
            <div class="join_form rounded-pill">
              <input type="email" v-model="email" placeholder="Enter your email" required>
              <button @click.prevent="submitForm">JOIN</button>
            </div>
            <p v-if="newsletterShow" class="msg-newsletter">Join successful!</p>
          </form>
        </div>
      </div>
    </div>
    <hr>
    <div class="footer_text_bottom">
      <h2>© 2024. All rights reserved.</h2>
      <h2>Developed BY <a href="https://www.bitquintet.com" class="text-white">BitQuintet</a></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtiveFooter",
  props: ['categories', 'site'],
  data() {
    return {
      categoriesData: JSON.parse(this.categories),
      siteData: JSON.parse(this.site),
      email: '',
      newsletterShow: false,
    };
  },
  methods: {
    generateVisitorId() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    async submitForm() {
      const csrftoken = this.getCookie('csrftoken');  // Get the CSRF token from cookies

      try {
        const response = await fetch('/dashboard/newsletter/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            newsletter: this.email,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          this.newsletterShow = true
          console.log('Successfully joined the newsletter:', data);
        } else {
          console.error('Failed to join the newsletter.');
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
      }
    },

    async trackUserActivity() {
      const csrfToken = this.getCookie('csrftoken');  // Corrected: Reference `this`

      let visitorId = localStorage.getItem('visitor_id');
      if (!visitorId) {
        visitorId = this.generateVisitorId();  // Corrected: Reference `this`
        localStorage.setItem('visitor_id', visitorId);
      }

      const activityData = {
        visitor_id: visitorId,
        path: window.location.pathname,
        method: 'GET',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      try {
        const response = await fetch('/capture-user-activity/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include the CSRF token in the headers
          },
          body: JSON.stringify(activityData),
        });

        const result = await response.json();
        if (result.status === "success") {
          console.log("User activity tracked:", result.activity_id);
        } else {
          console.error("Failed to track user activity");
        }
      } catch (error) {
        console.error("Error sending user activity data:", error);
      }
    },
  },

  mounted() {
    this.trackUserActivity();
    console.log(this.siteData)
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

hr {
  color: #FFFFFF;
  margin: 0 5rem;
}

.msg-newsletter {
  padding-top: 10px;
  text-align: center;
  font-size: 18px;
}

.Artiv_footer {
  background-image: url("./Artives-Footer-image/WEB_Footer.webp");
  background-position: center;
  background-size: cover;
}

.footer {
  position: relative;

  padding: 15rem 5rem 6rem 5rem;
  justify-content: space-between;
}

.footer .footer-mid {
  display: flex;
}

.footer > img {
  height: 750px;
}

.footer_text_area {
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  padding: 0 60px;
  width: 100%;
}

.sec_1 p {
  width: 300px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  color: #FFF;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.7px;
}

.sec_1 .social_icon {
  display: flex;
  gap: 1rem;
}

.sec_ {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.sec_ h2 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 172%;
  /* 27.52px */
}

.sec_ a {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 22.4px */
}

.sec_ p {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.join_form {
  border-radius: 50px;
  border: 1px solid #FFF;
  display: flex;
  justify-content: space-between;
}

.join_form input {
  background: transparent;
  color: #ABABAB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.7px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 15px;
  width: 70%;
}

.join_form button {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.7px;
  border-radius: 0px 50px 50px 0px;
  background: #FFF;
  padding: 20px;
}

.form-control:focus {
  color: #ABABAB;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.footer_text_top {
  padding-bottom: 50px;
}

.footer_text_bottom {
  padding: 30px;
  text-align: center;

}

.footer_text_bottom h2 {
  color: #ACACAC;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 172%;
  /* 27.52px */
}

.footer_text_two {
  display: none;
}

@media only screen and (max-width: 576px) {
  .footer {
    padding: 5rem 1.25rem 2rem 1.25rem;
  }

  .sec_1 p {
    width: 100% !important;
  }

  .footer-right {
    padding-bottom: 0rem;
  }

  .footer-mid {
    gap: 2rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .footer {
    display: block !important;
    padding-bottom: 2rem !important;
  }

  .footer-right.d-xl-none {
    display: none;
  }

  .footer-right.d-xl-block {
    display: block !important;
  }

  .footer .footer-left .footer_text_top {
    justify-content: center !important;
    align-items: center;
  }

  .footer .footer-mid {
    justify-content: center !important;
    align-items: start;
    padding-bottom: 40px;
  }

  .sec_1 {
    text-align: center;
  }

  .sec_1 .social_icon {
    justify-content: center;
  }

  .sec_1 p {
    width: 100% !important;
  }

  .footer-right .sec_ {
    width: -webkit-fill-available;
    text-align: center;
  }

  .footer-right {
    padding-bottom: 0rem;
  }

  .footer-mid {
    gap: 2rem !important;
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    padding-bottom: 0;
  }

  .footer-right {
    padding-bottom: 5rem;
  }


}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /*.footer_text_area {*/
  /*  position: absolute;*/
  /*  top: 30%;*/
  /*  transform: translateY(-10%);*/
  /*  padding: 0 40px;*/
  /*  width: 100%;*/
  /*}*/
  /*.sec_ {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  justify-content: space-between;*/
  /*  margin-bottom: 60px;*/
  /*  width: 100%;*/
  /*  text-align: center;*/
  /*}*/
  /*.footer_text_one {*/
  /*  display: none !important;*/
  /*}*/
  /*.footer_text_two {*/
  /*  display: block;*/
  /*}*/
}
</style>