<template>
  <div class="Artiv" :id="this.id">
    <ul class="nav nav-pills nav_pills" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab.id">
        <button class="nav-link nav_link" :class="{ active: tab.id === activeTab }"
                :id="'pills-' + tab.id + '-tab'" data-bs-toggle="pill"
                :data-bs-target="'#pills-' + tab.id" type="button" role="tab"
                :aria-controls="'pills-' + tab.id"
                :aria-selected="tab.id === activeTab">
          {{ tab.title }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div v-for="tab in tabs" :key="tab.id" class="tab-pane fade"
           :class="{ 'show active': tab.id === activeTab }"
           :id="'pills-' + tab.id" role="tabpanel"
           :aria-labelledby="'pills-' + tab.id + '-tab'" tabindex="0">
        <div class="two-img w-100">
          <div id="carouselExampleCaptions" class="carousel slide w-100 h-100">
            <div class="w-100 h-100">
              <div class="business-img w-100 h-100"
                   style="border-bottom-right-radius: 20px;border-bottom-left-radius: 20px;">
              </div>
              <img class="w-100 h-100" style="border-radius: 20px;"
                   :src="tab.imageSrc"
                   alt="">
              <div class="carousel-caption">
                <p class="business_card_text">{{ tab.caption }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';

export default {
  props: {
    jsonData: {
      type: String,
      default: JSON.stringify([
        {
          id: 'home',
          title: 'Corporate',
          imageSrc: './Artives-Interactive-Details-image/Rectangle 4218 (1).png',
          caption: 'Enhance Collaboration with Corporate Interactive Displays'
        },
        {
          id: 'profile',
          title: 'Education',
          imageSrc: './Artives-Interactive-Details-image/Rectangle 4218 (1).png',
          caption: 'Enhance Collaboration with Corporate Interactive Displays'
        },
        {
          id: 'conference',
          title: 'Conference',
          imageSrc: './Artives-Interactive-Details-image/Rectangle 4218 (1).png',
          caption: 'Enhance Collaboration with Corporate Interactive Displays'
        }
      ])
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const activeTab = ref('home');
    const tabs = ref([]);

    // Parse the JSON data when the prop changes
    watch(() => props.jsonData, (newValue) => {
      try {
        tabs.value = JSON.parse(newValue);
      } catch (e) {
        console.error('Failed to parse JSON:', e);
        tabs.value = [];
      }
    }, {immediate: true});

    return {
      activeTab,
      tabs
    };
  }
}
</script>

<style scoped>
.Artiv {
  margin-top: 80px;
}

.nav-pills {
  margin-bottom: 2.5rem;
}

.nav_pills {
  display: flex;
  width: fit-content;
  border-radius: 55px;
  padding: 5px;
  border: 1px solid #DADAD9 !important;
  margin: auto;
}

.nav-pills .nav_link.active,
.nav-pills .show > .nav_link {

  color: var(--bs-nav-pills-link-active-color);
  background: #1E1E1E !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1E1E1E;
  background: transparent !important;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 5.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
}

.business_card_text {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
}
</style>