<template>
  <div class="Artiv">
    <div id="carouselExampleCaptions" class="carousel slide w-100 h-100">
      <div class="carousel-inner w-100 h-100">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">

          <img src="https://artive.com.bd/media/images/WEB_Panel_Size.webp" class="d-none d-lg-block w-100 h-100" alt="...">
          <img class="d-block d-lg-none w-100 h-100" src="https://artive.com.bd/media/images/MOB_Panel_Size.webp">
          <div class="carousel-caption ">
            <h2 class="panel-head">Interactive Flat Panel</h2>
            <p class="panel-description">Interactive Display offers active learning through student engagement opportunities and Multi-Touch capabilities let groups of students edit and experience onscreen content as a team and brainstorm. Showing videos and slides can be enhanced to allow students to become involved in the learning process.</p>
            <a class="more_details" href="/products/Smart%20Board/#product-categories">More details
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none">
                <path d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697" stroke="white"
                      stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M3.5 12H20.33" stroke="white" stroke-width="2" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractivePanel"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.Artiv {
  width: 100%;
  height: fit-content;
  padding-top: 120px;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: start;
  width: 550px;
}

.panel-head {
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
  /* 51.2px */
  margin-bottom: 20px;
}

.panel-description {
  color: #FFF;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 60px;
}

.more_details {
  color: #FFF;
  text-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  font-variant: small-caps;
  padding: 12px 24px;
  border-radius: 6px;
  background: linear-gradient(96deg, #1BE5FE 0.24%, #108998 100.24%);
  display: inline-block;
}

.more_details svg {
  margin-left: 12px;
}

@media only screen and (max-width: 576px) {
  .Artiv {
    padding: 20px 0;
    height: 600px;
  }

  .carousel-caption {
    top: 30%;
    left: 15%;
    transform: translate(-10%, -40%);
    width: 300px;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    text-align: start;

  }

  .panel-head {
    font-size: 24px;
  }

  .panel-description {
    color: #FFF;
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .more_details {
    font-size: 14px;
    padding: 8px 14px;
  }

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .Artiv {
    padding: 40px 0;
    height: 700px;
  }

  .carousel-caption {
    top: 30%;
    left: 10%;
    transform: translate(-10%, -40%);
    width: 400px;
  }

  .panel-head {
    font-size: 30px;
  }

  .panel-description {
    font-size: 14px;
  }

  .more_details {
    font-size: 14px;
    padding: 8px 24px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .carousel-caption {
    top: 40%;
    left: 10%;
    transform: translate(-10%, -40%);
    width: 450px;
  }
}
</style>