<template>
  <nav id="navbar-example2" class="navbar overflow-x-scroll navi_gation bg-white px-3 w-100"
       style="box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);">
    <ul class="nav flex-nowrap nav-pills nav_pill d-flex justify-content-center w-100">
      <li v-for="item in parsedNavItems" :key="item.id" class="nav-item d-flex text-nowrap">
        <!-- The click event handler correctly bound to the method -->
        <a style="padding: 0.5rem 2rem" class="nav-link interactive_items"
           :href="'#' + item.id"
           @click.prevent="scrollToSectionHandler(item.id)">
          {{ item.title }}
        </a>
        <div class="vr" v-if="item !== parsedNavItems[parsedNavItems.length - 1]"></div>
      </li>
    </ul>

  </nav>
  <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%"
       data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary rounded-2" tabindex="0">
    <!-- Product subsection enters here -->
  </div>
</template>

<script>
export default {
  name: "ProductNavbar",
  props: {
    dataNavItems: {
      type: String,
      required: true,
      default: JSON.stringify(
          [
            {id: 'scrollspyHeading1', title: 'Operating System'},
            {id: 'scrollspyHeading2', title: 'Display'},
            {id: 'scrollspyHeading3', title: 'Camera'},
            {id: 'scrollspyHeading4', title: 'Sound'},
            {id: 'scrollspyHeading5', title: 'Features'},
            {id: 'scrollspyHeading6', title: 'Specifications'},
            {id: 'scrollspyHeading7', title: 'Download'}
          ]
      )
    }
  },
  computed: {
    parsedNavItems() {
      try {
        return JSON.parse(this.dataNavItems);
      } catch (error) {
        console.error('Invalid JSON string:', error);
        return [];
      }
    }
  },
  methods: {
    // Renamed the method to ensure there's no internal conflict
    scrollToSectionHandler(id) {
      const section = document.getElementById(id);

      // Get the height of both navbars
      const navbar1 = document.querySelector('.Artive-nav-hero'); // First navbar selector
      const navbar2 = document.querySelector('#navbar-example2'); // Second navbar

      const navbar1Height = navbar1 ? navbar1.offsetHeight : 0;
      const navbar2Height = navbar2 ? navbar2.offsetHeight : 0;

      if (section) {
        const totalOffset = navbar1Height + navbar2Height + 10; // Add padding of 10px
        const offsetPosition = section.offsetTop - totalOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style scoped>
.navi_gation {
  position: sticky;
  top: 100px;
  z-index: 2;
}

.navbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.interactive_items {
  color: #1E1E1E;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
  font-variant: all-small-caps;
}

@media only screen and (max-width: 576px) {
  .interactive_items {
    color: #1E1E1E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-variant: all-small-caps;
  }

  .navi_gation {
    position: sticky;
    top: 60px;
    z-index: 1;
  }

  .nav_pill {
    justify-content: normal !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .interactive_items {
    color: #1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-variant: all-small-caps;
  }

  .navi_gation {
    position: sticky;
    top: 60px;
    z-index: 1;
  }

  .nav_pill {
    justify-content: normal !important;
  }
}
</style>