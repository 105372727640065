<template>
  <div class="about-section" style="margin-bottom: 5rem;">
    <div class="about-top">
      <div class="carousel slide">

        <div class="carousel-inner">
          <div class="w-100 h-100">
            <img src="https://artive.com.bd/media/images/WEB_About_MpVnL0G.webp" alt="..."
                 class="d-none d-md-block w-100 h-100" style="object-fit: cover;">
            <img src="https://artive.com.bd/media/images/MOB_About_OYgoljl.webp"
                 class="d-block d-md-none w-100 h-100" alt="..." style="object-fit: cover;">
            <div class="carousel-caption carousel_caption_one">
              <h1 class="about-top-head">EVERY MOMENT, A VISUAL <br>MASTERPIECE</h1>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="about-mid">
      <div class="carousel slide">

        <div class="carousel-inner" style="height: 850px;">
          <div class="w-100 h-100 position-relative">
            <div class="gradient"></div>
            <img src="./Artives-About-image/Rectangle 4230.png" class="d-block w-100 h-100" alt="...">
            <div class="carousel-caption carousel_caption_two">
              <div class="mid_header">
                <h2>About <span>Artive</span></h2>
              </div>
              <div class="d-flex align-items-center about-div" style="gap: 5rem;">
                <div class="video_box w-md-50 w-100">
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/0A1nD9h3-2s?si=R0R5G5TSHlYmagl0"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="about-mid-text w-md-50 w-100">
                  <p>
                    With a passion for innovation, we introduce ARTIVE as an immersive experiences
                    that blend captivating visuals with engaging audio products to redefine the way
                    people interact with multimedia, making it more intuitive, enjoyable, and
                    meaningful. Whether it's entertainment, education or communication; ARTIVE is at
                    the forefront of delivering cutting-edge experiences that resonate with
                    audiences by offering a unique combination of creativity and technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-bottom">
      <div class="carousel slide">

        <div class="carousel-inner" style="height: 1080px;">
          <div class="w-100 h-100 position-relative">
            <div class="gradient"></div>
            <img src="./Artives-About-image/n.png" class="d-block w-100 h-100" alt="...">
            <div class="carousel-caption carousel_caption_two">
              <div class="bottom_header">
                <h2>About <span>Netbiz</span></h2>
              </div>
              <div class="d-flex align-items-center about-div" style="gap: 5rem;">
                <div class="video_box w-md-50 w-100">
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/nQvrECgA6yU?si=GD3gn6NBigy2S2qj"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="about-mid-text w-md-50 w-100">
                  <p>
                    NETBIZ Technologies is an associates of Richman Informatics specializes in Online & Interactive
                    Audio Visual Solution in education, government, and corporate spheres. We offer Collaborative
                    Meeting, Smart Conference, AVoIP Conference, Paperless Training and Digital Signage.
                    We have started our operation since 2011 and performed social responsibilities from April, 2020 as a
                    COVID response towards the society for ensuring a safe and healthy working environment.
                    We have been resolutely dedicated in offering end customers to design, plan, integrate and install a
                    comfortable and convenient solution to meet their diverse demands and make them SMARTER they are!
                    We hope to have customers’ confidence with the highest functional and reliable products at the
                    competitive price by progressively building up skill.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>
a {
  text-decoration: none;
}
.carousel-inner {
  height: 810px;
}

.carousel-caption.carousel_caption_one {
  left: 10%;
  right: 0;
  top: 32%;
}
.about-top-head {
  color: #1E1E1E;
  text-align: start;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.carousel_caption_one {
  bottom: 20.25rem;
}

.about-mid {
  border-top: 10px solid #1AE5FE;
}

.about-bottom {
  border-top: 10px solid #1AFE5E;
}

.video_box {

  height: 350px;
  border-radius: 20px;
  background: #00B9D0;
}

.gradient {
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.70);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.carousel_caption_two {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  width: 90%;
}

.about-mid-text p {
  color: #FFF;
  text-align: justify;
  text-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.48px;
}


.bottom_header {
  margin-bottom: 70px;
}

.bottom_header h2 {
  color: #FFF;
  text-align: center;
  font-size:3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 59.52px */
  text-transform: uppercase;
}

.bottom_header span {
  color: #24FF3E;
}

.mid_header {
  margin-bottom: 70px;
}

.mid_header h2 {
  color: #FFF;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 59.52px */
  text-transform: uppercase;
}

.mid_header span {
  color: #1AE5FE;
}

@media only screen and (max-width: 768px) {
  .mid_header, .bottom_header {
    margin-bottom: 2.5rem;
  }
  .mid_header h2, .bottom_header h2 {
    font-size: 2rem;
  }
  .about-mid-text p {
    font-size: 14px;
  }
  .about-div {
    flex-direction: column;
    gap: 2.5rem !important;
  }
  .carousel_caption_two {
    top: 35% !important;
  }
  .about-top-head {
    font-size: 1.75rem;
  }
  .carousel-inner {
    height: 600px;
  }
  .carousel-caption.carousel_caption_one {
  left: 5%;
  right: 0;
  top: 15%;
}
  .about-top-head {
  font-size: 24px;
}
}

@media only screen and (max-width: 991px) {

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
</style>