<template>
  <div class="Artiv">

    <main class="container-fluid p-0">
      <div class="w-100 h-100">
        <dialog :open="isModalOpen" class="dialog " @click="closeModal" style="width: 100%; height: 100vh; ">
          <div>
            <!--            <div class="d-flex justify-content-end close-modal-btn" style="margin-right: 6rem">-->
            <!--              <button class="close-btn btn" @click="closeModal">X</button>-->
            <!--            </div>-->
            <div class="modal-video d-flex justify-content-center">
              <iframe
                  style="width: 80%; height: 80vh; border-radius: 20px;"
                  :src="currentVideoUrl"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
        </dialog>
      </div>

      <div class="row no-gutters m-0">
        <div class="col-12 p-0">
          <Carousel
              :wrap-around="true"
              :centeredSlides="true"
              :slidesToShow="1"
              :slidesToScroll="1"
              :navigationEnabled="true"
              :paginationEnabled="true"
              :autoplay="4000"
          >
            <Slide v-for="(slide, index) in slides" :key="index" class="carousel-slide">
              <div class="carousel-background d-none d-lg-block"
                   :style="{ backgroundImage: `url(${slide.image})`}"></div>
              <div class="carousel-background d-block d-lg-none"
                   :style="{ backgroundImage: `url(${slide.mov_img})`}"></div>
              <div class="contain  full-width">
                <div class="row hero_des align-items-center ">
                  <div class="col-12 text-start ">
                    <h2 class="main_head " :style="{ color: slide.color }">{{ slide.title }}</h2>
                    <p class="description text-justify" :style="{ color: slide.color }">{{ slide.description }}</p>
                    <div class="learn_more">
                      <a class="learn_more_btn" href="/products/Smart%20Board/#product-categories"
                         :style="{ color: slide.color, borderColor: slide.color  }">Learn more
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                          <path d="M10.7137 1L16.7137 7M16.7137 7L10.7137 13M16.7137 7L1.28516 7" :stroke="slide.color"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                      <div class="play_btn" :style="{ color: slide.color }" @click="openModal(slide.videoUrl)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23 1C35.1484 1 45 10.8494 45 23C45 35.1506 35.1484 45 23 45C10.8494 45 1 35.1506 1 23C1 10.8494 10.8494 1 23 1Z"
                                :stroke="slide.color" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M29.9466 22.9878L19.2266 16.1807V29.795L29.9466 22.9878Z" :stroke="slide.color"
                                stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Slide>

            <!--            <div>-->
            <!--              <vue-final-modal v-model="isModalOpen">-->
            <!--                <span class="modal__title">Hello, vue-final-modal</span>-->
            <!--              </vue-final-modal>-->
            <!--              <v-button @click="isModalOpen = true">Open modal</v-button>-->
            <!--            </div>-->
            <template #addons>
              <Pagination/>
            </template>
          </Carousel>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Carousel, Pagination, Slide} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'ArtivCarousel',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    slides: {
      type: Array,
      default: () => [
        {
          image: 'https://artive.com.bd/media/images/WEB_Home_Hero_1_CL4c7Lu.webp',
          mov_img: 'https://artive.com.bd/media/images/MOB_Home_Hero_1_H5RLc8h.webp',
          title: 'All-in-One Interactive Flat Panel',
          description: 'Take Collaboration to the Next Level',
          color: '#1E1E1E',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          image: 'https://artive.com.bd/media/images/WEB_Home_Hero_2_EewWi5S.webp',
          mov_img: 'https://artive.com.bd/media/images/MOB_Home_Hero_2_uRvM0Tm.webp',
          title: 'DIgital Signage',
          description: 'Light Up Every Moment',
          color: '#1E1E1E',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          image: 'https://artive.com.bd/media/images/WEB_Home_Hero_3.webp',
          mov_img: 'https://artive.com.bd/media/images/MOB_homehero3.webp',
          title: 'Mini Projector',
          description: 'For brilliant large-screen experience',
          color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          image: 'https://artive.com.bd/media/images/WEB_Home_Hero_4.webp',
          mov_img: 'https://artive.com.bd/media/images/MOB_homehero4.webp',
          title: 'Wireless Receiver',
          description: 'Clear Sound Pickup and Transmission.',
          color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          image: 'https://artive.com.bd/media/images/WEB_Home_hero5.webp',
          mov_img: 'https://artive.com.bd/media/images/MOB_Home_Hero5.webp',
          title: 'Smart conference',
          description: 'Be smart, Go online, Be interactive',
          color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
      ],
    },
  },
  data() {
    return {
      isModalOpen: false,
      currentVideoUrl: '',
    };
  },
  computed: {
    youtubeEmbedUrl() {
      return this.currentVideoUrl.replace("watch?v=", "embed/");
    },
  },
  methods: {
    openModal(videoUrl) {
      this.currentVideoUrl = videoUrl.replace("watch?v=", "embed/");
      this.isModalOpen = true;
      document.body.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (this.isModalOpen) {
        // Check if the click is outside the modal content
        if (!this.$refs.modalDialog.contains(event.target)) {
          this.currentVideoUrl = '';
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.currentVideoUrl = '';
      this.isModalOpen = false;
    }
  },
});
</script>

<style scoped>
a {
  text-decoration: none;
  background: transparent;
}

.play_btn {
  cursor: pointer;
}

.carousel-slide {
  position: relative;
  height: 100vh;

  min-height: 100vh;
}

.carousel__slide {
  justify-content: start !important;
}

.dialog {
  z-index: 9999;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: rgb(255 255 255 / 0%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.close-modal-btn {
  margin-right: -2rem;
}

.modal-video {
  width: 100%;
  height: fit-content;
}

.carousel-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.carousel-caption {
  position: absolute;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 2rem;
}

.main_head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.description {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.07px;
}

.hero_bg_img {
  width: 100%;
}

.learn_more {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.learn_more_btn {
  border-radius: 4px;
  border: 1.5px solid #1E1E1E;
  padding: 12px 20px;
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.learn_more_btn svg {
  margin-left: 15px;
}


.main_head {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  font-size: 1.25rem;
}

.learn_more_btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  border-radius: 0.25rem;
}


.play_btn {
  display: inline-flex;
  align-items: center;
}

.contain {
  margin: 0 80px !important;
}

@media only screen and (max-width: 576px) {
  .carousel-slide {
    position: relative;
    height: fit-content;
  }

  .carousel-caption {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally on mobile */
    justify-content: flex-start; /* Align to top on mobile */
    text-align: center;
    padding: 1rem; /* Adjust padding as needed */
  }

  .main_head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .description {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.05px;
    width: 100%;
  }

  .learn_more {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .hero_des {
    margin-top: -21rem;
  }

  .carousel-background {
    background-position: right;
  }

  .contain {
    margin: 0 20px !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .main_head {
    font-size: 28px;
    font-weight: bold;
  }

  .hero_des {
    margin-top: -21rem;
  }

  .carousel-background {
    background-position: right;
  }

  .contain {
    margin: 0 20px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contain {
    margin: 0 20px !important;
  }
}

</style>
