<template>
  <div class="part_five_bottom d-flex justify-content-between" style="padding: 5rem; gap: 2.5rem;">
    <div v-for="(card, index) in cards" :key="index" class="card_area" style="padding: 40px;">
      <img class="w-100" :src="card.imageSrc" alt="">
      <div class="card_text" style="margin-top: 24px;">
        <h3>{{ card.title }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';

export default {
  name: 'PartFiveBottom',
  props: {
    jsonData: {
      type: String,
      default: JSON.stringify([
        {
          imageSrc: './Artives-Interactive-Details-image/image 122.png',
          title: 'Flexible Sizes'
        },
        {
          imageSrc: './Artives-Interactive-Details-image/image 122 (1).png',
          title: 'Ink Pen x2'
        },
        {
          imageSrc: './Artives-Interactive-Details-image/image 123.png',
          title: 'Remote Control x1'
        }
      ])
    }
  },
  setup(props) {
    const cards = ref([]);

    // Parse the JSON data when the prop changes
    watch(() => props.jsonData, (newValue) => {
      try {
        cards.value = JSON.parse(newValue);
      } catch (e) {
        console.error('Failed to parse JSON:', e);
        cards.value = [];
      }
    }, {immediate: true});

    return {
      cards
    };
  }
}
</script>

<style scoped>
.card_area {
  background: white;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
}

.card_text h3 {
  color: #1E1E1E;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: center;
}
</style>