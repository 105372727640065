<template>
  <div class="hybrid-classroom" style="margin-bottom: 5rem">
    <div class="about-top">
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-inner first_img">
          <div class="w-100 h-100 position-relative">
            <img src="https://artive.com.bd/media/images/WEB_Paperless_KSJE4zW.webp" alt="..."
                 class="d-none d-md-block w-100 h-100" style="object-fit: cover;">
            <img src="https://artive.com.bd/media/images/MOB_Paperless_1_.webp"
                 class="d-block d-md-none w-100 h-100" alt="..." style="object-fit: cover;">
            <div class="carousel-caption carousel_caption_one">
              <h1 class="about-top-head">PAPERLESS CLASSROOM SOLUTION
              </h1>
              <p class="about-top-descrip">Superior stability and durability, crafted with high-quality materials for
                long-lasting performance.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="smart_classroom">
        <div class="w-100 w-lg-50">
          <h2 class="smart_modern">Smart Solutions for Paperless Classrooms</h2>
        </div>
        <div class="w-100 w-lg-50">
          <p class="smart_modern_description">A Paperless classroom solutions leverages digital tools and technology to
            eliminate the need for physical paper in educational settings. This approach includes using tablets, laptops
            and interative displays for assignments, notes, and communication.</p>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB__Paperless_2.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="pad" style="padding: 80px;">
            <h2 class="more_edu_head">Features for Effective Learning</h2>
            <p class="more_edu_descrip">Interactive tools like digital displays to create a more dynamic and immersive
              learning environment, enhancing student participation and collaboration.</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 wireless">
          <div class="pad" style="padding: 80px;">
            <h2 class="more_edu_head">Note-Taking Made Easy</h2>
            <p class="more_edu_descrip">create engaging, interactive, and adaptable learning environments using
              technology like SMART Boards to enhance lessons, foster collaboration, and make learning more
              effective.</p>
          </div>
        </div>
        <div class="w-100 w-lg-50 third_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB__Paperless_3.webp" alt="">
        </div>

      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB__Paperless_4.webp" alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track">
          <div class="pad" style="padding: 80px;">
            <h2 class="more_edu_head">Annotate, Design with Touch and Pen</h2>
            <p class="more_edu_descrip">Interactive tools like digital displays to create a more dynamic and immersive
              learning environment, enhancing student participation and collaboration.</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 wireless">
          <div class="pad" style="padding: 80px;">
            <h2 class="more_edu_head">Digitizer Pen and Audio</h2>
            <p class="more_edu_descrip">create engaging, interactive, and adaptable learning environments using
              technology
              like SMART Boards to enhance lessons, foster collaboration, and make learning more effective.</p>
          </div>
        </div>
        <div class="w-100 w-lg-50 third_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB__Paperless_5.webp" alt="">
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PaperlessClassroom"
}
</script>

<style scoped>
a {
  text-decoration: none;
}
.carousel-inner {
  height: 810px;
}



.about-top-head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 76.8px */
  text-transform: capitalize;
}


.carousel_caption_one {
  position: absolute;
  right: 6%;
  top: 25%;
  left: 4.8%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
  width: 500px;
  margin-bottom: 15px;
}

.about-top-descrip {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.smart_classroom {
  padding: 80px;
  gap: 80px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
}

.classroom {
  background: #F7F7F7;
}

.smart_modern {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  width: 500px;
}

.smart_modern_description {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.more_edu_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.more_edu_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.class_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_ul li {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 228.571% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .carousel-inner {
    height: 600px;
  }
  .pad{
    padding: 20px !important;
  }

  .carousel_caption_one {
    position: absolute;
    right: 6%;
    bottom: 3rem;
    left: 6%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
    width: 90%;
  }
  .carousel_caption_one {
  position: absolute;
  right: 6%;
  top: 25%;
  left: 2.8%;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }

  .third_img {
    height: 400px !important;
  }

  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .carousel_caption_one {
    position: absolute;
    right: 6%;
    left: 6%;
    top: 35%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
  }
   .carousel_caption_one {
  position: absolute;
  right: 6%;
  top: 25%;
  left: 2.8%;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }

  .second_img {
    height: 350px !important;
  }


  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head{
    color: #1E1E1E;
    font-size: 28px;
  }


  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .carousel_caption_one {
  position: absolute;
  right: 6%;
  top: 25%;
  left: 2.8%;
  }
}
</style>