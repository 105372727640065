<template>
  <div class="specification" :id="this.id">
    <div class="specification-head mb-5">
      <h2>Specifications</h2>
    </div>
    <div class="row main m-0">
      <div class="col-3 d-none d-lg-block">
        <div id="list-example" class="list-group List_grp">
          <a
            v-for="(section, index) in specificationData"
            :key="index"
            class="list-group-item list-group-item-action"
            @click="scrollToSection(index + 1)">
            {{ section.title }}
          </a>
        </div>
      </div>
      <div class="col-8 main_bottom" ref="mainBottom">
        <div class="scrollspy-example" tabindex="0">
          <div
            v-for="(section, index) in specificationData"
            :key="index"
            :id="`list-item-${index + 1}`">
            <div class="header_area">{{ section.title }}</div>
            <table class="table">
              <tbody>
              <tr v-for="(item, itemIndex) in section.items" :key="itemIndex">
                <th scope="row" style="width: 30% !important; text-wrap: wrap;">{{ item.label }}</th>
                <td v-if="item.values.length === 1" :colspan="maxColumns(section)" style="text-align: center;">
                  {{ item.values[0] }}
                </td>
                <td v-else v-for="(value, valueIndex) in item.values" :key="valueIndex">
                  {{ value }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSpecification",
  props: {
    specifications: {
      type: String,
      default: JSON.stringify([
        {
          title: "Display",
          items: [
            { label: "Model", values: ["ATL-11D2100", "ATL-11D2110"] },
            { label: "Size", values: ["100″ LED Backlight", "100″ LED Backlight"] },
            { label: "Active Area (mm)", values: ["2158.8(H) * 1214.3(V)", "2158.8(H) * 1214.3(V)"] },
          ]
        },
        {
          title: "Panel",
          items: [
            { label: "Resolution", values: ["3840*2160 /60hz"] },
            { label: "Aspect Ratio", values: ["16:09"] },
          ]
        },
      ]),
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    specificationData() {
      return JSON.parse(this.specifications);
    }
  },
  methods: {
    maxColumns(section) {
      return Math.max(...section.items.map(item => item.values.length));
    },
    scrollToSection(index) {
      const target = this.$refs.mainBottom.querySelector(`#list-item-${index}`);
      if (target) {
        this.$refs.mainBottom.scrollTo({
          top: target.offsetTop,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>


<style scoped>
* {
  position: relative;
  box-sizing: border-box;
}
.header_area{
  font-size: 18px;
  font-weight: 600;
}
table, td, th {
  border: 1px solid #dee2e6;
}

td,th{
  font-size: 14px;
  font-weight: normal;
}
.specification {
  background: black;
  padding: 80px;
  width: 100%;

}

.specification-head h2 {
  color: #FFF;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
}

.main {
  gap: 60px;


}

.List_grp {
  max-width: 300px;
  position: sticky;
  top: 60px;
}

.main_bottom {
  border-radius: 8px;
  border: 1px solid #6A6A6A;
  background: #FFF;
  height: 700px;
  overflow-y: auto;
}


@media only screen and (max-width: 576px) {
  .specification {
    background: black;
    padding: 20px;
    width: 100%;
  }

  .main {
    gap: 0;
  }

  .specification-head h2 {
    color: #FFF;
    text-align: center;
    font-size: 28px;

  }
  .main_bottom {
    border-radius: 8px;
    border: 1px solid #6A6A6A;
    background: #FFF;
    height: 500px;
    overflow: scroll;
    width: 100%;
}

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .specification {
    background: black;
    padding: 40px;
    width: 100%;
  }

  .main {
    gap: 0;
  }

  .specification-head h2 {
    color: #FFF;
    text-align: center;
    font-size: 35px;

  }
   .main_bottom{
    border-radius: 8px;
    border: 1px solid #6A6A6A;
    background: #FFF;
    height: 600px;
    overflow: scroll;
    width: 100%;
}

}
</style>