<template>
  <div class="Artiv">

    <!--------------  Hero Area  --------------------->

    <div class="hero_area ">
      <div id="carouselExampleCaptions" class="carousel slide w-100 h-100">
        <div class="carousel-inner w-100 h-100">
          <div class="carousel_item w-100 h-100">
            <div class=" hero_area_text ">
              <h1 class="main_head">The Highest Quality</h1>
              <p class="description">Upgrade! Smater than it is!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center" style="gap: 20px; margin-top: 60px;">
      <h2 class="sub_head">Let's Collaborate Effortlessly!</h2>
      <p class="text-center" style="max-width: 700px;">
        In today's changing workplace, good communication and teamwork are vital.
        Interactive Displays help make meetings and teaching more creative and engaging.
      </p>
    </div>
    <div class="collaborate" id="product-categories">
      <ul class="nav nav-pills hide_scroll d-flex justify-content-center category-list" id="pills-tab" role="tablist"
          style="margin-bottom: 60px; overflow-x: auto; white-space: nowrap; flex-wrap: nowrap;">
        <div class="d-flex align-items-center justify-content-center " v-for="(category, index) in filteredCategories"
             :key="category.id">
          <li class="nav-item" role="presentation" style="margin: 0 1rem; flex-shrink: 0;">
            <button
                class="nav-link"
                :class="{ active: category.title === activeCategoryId }"
                :id="'pills-' + category.id + '-tab'"
                data-bs-toggle="pill"
                :data-bs-target="'#pills-' + category.id"
                type="button"
                role="tab"
                :aria-controls="'pills-' + category.id"
                :aria-selected="index === 0"
                @click="setActiveCategory(category.title)">
              <div class="pad d-flex flex-column align-items-center" style="gap: 20px; padding: 20px 40px;">
                <img :src="category.icon" :alt="category.title" width="100" height="61">
                <h2 class="product_name">{{ category.title }}</h2>
              </div>
            </button>
          </li>
          <div class="vr" v-if="index < categoriesData.length - 1"></div>
        </div>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade"
             v-for="(category) in categoriesData"
             :class="{ 'show active': category.title === activeCategoryId }"
             :id="'pills-' + category.id"
             role="tabpanel"
             :aria-labelledby="'pills-' + category.id + '-tab'"
             tabindex="0" :key="category.id">
          <div class="lets_collab">

            <div class="card_item">
              <div class="card" v-for="product in filteredProducts(category.id)" :key="product.id">
                <a :href="`/product/${product.slug}`">
                  <div class="card_img">
                    <img :src="product.image" class="card-img-top" alt="...">
                  </div>
                  <div class="card-body w-100">
                    <div class="d-flex align-items-center justify-content-between card_body_header">
                      <h2 class="mb-0">{{ product.title }}</h2>
                      <a href="" class="for_popular">POPULAR</a>
                    </div>
                    <div class="d-flex align-items-center justify-content-between card_body_footer">
                      <p class="mb-0">{{ product.type }}</p>
                      <div class="d-flex gap-2 card_body_custom">
                        <a v-for="size in product.sizes" :key="product.id + '-' + size">{{ size }}</a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

            </div>
            <div v-if="filteredProducts(category.id).length === 0" class="text-center fw-bold fs-1">No Products
              Found!!
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "AllProduct",
  props: ['products', 'categories', 'category'],
  components: {},
  data() {
    return {
      productsData: JSON.parse((this.products)),
      categoriesData: JSON.parse(this.categories),
      activeCategoryId: this.category ? decodeURI(this.category) : this.categoriesData[0].id,
      slides: [
        {
          image: 'https://artive.com.bd/media/images/SOLUTION_HERO_2.webp',
          title: 'The Highest Quality',
          description: 'Upgrade! Smarter than it is!',
        },
        {
          image: 'https://artive.com.bd/media/images/sOLUTION_HERO1.webp',
          title: 'Digital Signage for Every Environment',
          description: 'These displays provide a simple and effective way to share messages in different environments.',
        },
        {
          image: 'https://artive.com.bd/media/images/Hero3.webp',
          title: 'Mini Projector, Mighty Performance',
          description: 'The mini projector is equipped with an AOSP Android 9.0 operating system, allowing you to enjoy a wide range of apps and features similar to those on a smartphone or tablet.',
        },
        {
          image: 'https://artive.com.bd/media/images/Hero4.webp',
          title: 'Top-Tier Stand for Professionals',
          description: 'High-quality materials to provide exceptional stability and durability, making it perfect for professional use in any setting.',
        },
      ],
    }
  },
  computed: {
    filteredCategories() {
      console.log(this.activeCategoryId);
      return this.categoriesData.filter(category => category.in_display);
    }
  },
  mounted() {
    // this.logProps();
  },
  methods: {
    logProps() {
      console.log("Products:", this.productsData);
      console.log("Categories:", this.categoriesData);
      console.log(this.activeCategoryId);
    },
    setActiveCategory(categoryTitle) {
      this.activeCategoryId = categoryTitle;
    },
    filteredProducts(categoryId) {
      return this.productsData.filter(product => product.category === categoryId);
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

/* <!--------------  Hero Area  --------------------->  */
.card-img-top {
  /*height: 186px;*/
  height: fit-content;
}

.carousel_item {
  background: url('https://artive.com.bd/media/images/WEB_All_Product_Z4VdbXc.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 80px;

}

.hero_area_text {

}

.hero_area {
  height: 800px;
}


.main_head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  /* 112.5% */
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.description {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.hero_bg_img {
  height: 90vh;
}

.learn_more {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.learn_more_btn {
  border-radius: 4px;
  border: 1.5px solid #1E1E1E;
  padding: 12px 20px;
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */

}

.learn_more_btn svg {
  margin-left: 15px;
}

.carousel-indicators {
  bottom: 60px;
  gap: 20px;
}

.carousel-indicators .active {
  opacity: 1 !important;
  background: #3E4248;
}

.carousel-indicators  {
  box-sizing: content-box;
  flex: 0 1 auto;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  opacity: .5;
  transition: opacity .6s ease;
  width: 50px;
  /* border-radius: 7px; */
  height: 4px;
}

.collaborate {
  padding: 60px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 8px;
  background: #F4F4F4;
}

.nav-link {
  padding: 0;
}


.product_name {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.08px;
  text-transform: capitalize;
}

.sort_by {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.7px;
  margin-bottom: 0;
}

.drop_area {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.7px;
  padding: 6px 16px;
  border-radius: 2px;
  border: 1px solid #CCC;
}

.dropdown-toggle::after {
  vertical-align: 2px;
}

.card_item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px;
}

.card {
  background: #F9F9FB;
  box-shadow: 105px 123px 45px 0px rgba(110, 110, 110, 0.00), 67px 79px 41px 0px rgba(110, 110, 110, 0.01), 38px 44px 35px 0px rgba(110, 110, 110, 0.03), 17px 20px 26px 0px rgba(110, 110, 110, 0.04), 4px 5px 14px 0px rgba(110, 110, 110, 0.05);
  /*height: 422px;*/
  --bs-card-border-radius: none !important;
  --bs-card-border-color: transparent !important;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.card_img {
  background-image: url("./Artives-All-Product-image/image-background.png");
  padding: 60px 46px;
  width: 100%;
}

/*.card_img img {*/
/*  width: 100%;*/
/*  height: fit-content;*/
/*}*/

.card-body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card_body_header h2 {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.card_body_header .for_popular {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #FC9B00;
  box-shadow: 0px 0px 14px 1px rgba(252, 155, 0, 0.50);
}

.card_body_header .for_new {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #34C759;
  box-shadow: 0px 0px 14px 1px rgba(52, 199, 89, 0.50);
}

.card_body_header .for_upcomming {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #565758;
  box-shadow: 0px 0px 14px 1px rgba(125, 125, 125, 0.50);
}

.card_body_footer p {
  color: #848E99;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.56px;
  /* 179.667% */
  text-transform: capitalize;
}

.card_body_custom a {
  color: #848E99;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.25px;
  /* 143.75% */
  border-radius: 6px;
  border: 1px solid #C7D0D9;
  padding: 4px;
}

.sub_head h2 {
  font-size: 48px;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

.hide_scroll {
  scrollbar-width: none;
}

@media only screen and (max-width: 991px) {
  .card_item {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    gap: 20px;
  }

  .hero_area {
    height: 400px;
  }

  .carousel_item {
    background: url('https://artive.com.bd/media/images/MOB_All_product.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }


  .hero_area_text {
    width: 360px;
    margin-top: -170px;
  }

  .main_head {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
  }

  .pad {
    padding: 20px 30px !important;
  }

  .sub_head h2 {
    font-size: 24px;
  }

  .carousel_item {

    padding-left: 20px;
  }

}

@media only screen and (max-width: 768px) {
  .collaborate {
    padding: 1.25rem;
  }

  .sub_head h2 {
    text-align: center;
    font-size: 35px;
  }

  .lets_collab p {
    text-align: justify !important;
  }

  .card_img {
    padding: 38px 30px;
  }

  .card {
    /*width: 335px;*/
  }

  .category-list {
    justify-content: start !important;
  }

  .category-list::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  .category-list::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  .category-list::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 5px solid white; /* creates padding around scroll thumb */
  }

  .carousel_item {

    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .hero_bg_img {
    height: auto;
  }

  .carousel_item {

    padding-left: 20px;
  }

  .carousel-caption {
    transform: translate(-32%, -8%);
    top: 8%;
  }


  .main_head {
    font-size: 40px;

  }

  .collaborate {
    padding: 40px;
  }

  .pad {
    padding: 20px 30px !important;
  }
}
</style>