<template>
  <div class="w-100 part_three_bottom" :id="this.id" style="padding: 80px;">
    <h2>{{ title }}</h2>

    <div class="w-100 part_one_bottom p-0">
      <div class="w-50 one_bottom_left">
        <img class="w-100" :src="leftImage.src" :alt="leftImage.alt">
        <h3>{{ leftText }}</h3>
      </div>
      <div class="w-50 one_bottom_right">
        <img class="w-100" :src="rightImage.src" :alt="rightImage.alt">
        <h3>{{ rightText }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubseven",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        title: "Reduce Eye Strain with Dynamic Anti-Backlight",
        leftImage: {
          src: "./Artives-Interactive-Details-image/Rectangle 4244.png",
          alt: "Artive Panel Camera"
        },
        leftText: "Artive Panel Camera",
        rightImage: {
          src: "./Artives-Interactive-Details-image/Rectangle 4245.png",
          alt: "Others Panel Camera"
        },
        rightText: "Others Panel Camera"
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    },
    title() {
      return this.contentData.title || '';
    },
    leftImage() {
      return this.contentData.leftImage || {src: '', alt: ''};
    },
    leftText() {
      return this.contentData.leftText || '';
    },
    rightImage() {
      return this.contentData.rightImage || {src: '', alt: ''};
    },
    rightText() {
      return this.contentData.rightText || '';
    }
  }
}
</script>

<style scoped>
/* Add any specific styling needed for the component here */
</style>

<style scoped>
.part_three_bottom h2 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 128%;
  /* 40.96px */
  text-align: center;
  margin-bottom: 80px;
}

.part_one_bottom {
  display: flex;
  gap: 80px;
  padding: 80px;
  align-items: center;
  justify-content: space-between;
  background: #F7F7F7;
}

.one_bottom_left,
.one_bottom_right {
  text-align: center;

}

.one_bottom_left img,
.one_bottom_right img {
  border-radius: 10px;
  margin-bottom: 35px;
}

.one_bottom_left h3,
.one_bottom_right h3 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
  /* 30.72px */
}

@media only screen and (max-width: 576px) {
  .part_three_bottom {
    padding: 20px !important;
  }

  .part_three_bottom h2 {
    color: #000;
    font-size: 28px;
  }

  .part_one_bottom {
    display: flex;
    gap: 20px;
    padding: 80px;

  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 128%;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_three_bottom {
    padding: 20px !important;
  }

  .part_three_bottom h2 {
    color: #000;
    font-size: 28px;
  }

  .part_one_bottom {
    display: flex;
    gap: 20px;
    padding: 80px;

  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 128%;
  }
}
</style>
