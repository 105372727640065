<template>
  <div class="top-solution">
    <div class="top-solution-header">
      <h2>Explore Top Solutions</h2>
    </div>
    <div class="">
      <dialog :open="isModalOpen" class="dialog border-0" style="width: 100%; backdrop-filter: blur(5px); z-index: 2;  background: #ffffff00;">
        <div>
          <div class="d-flex justify-content-end close-modal-btn" style="margin-right: 6rem">
            <button class="close-btn btn" @click="closeModal">X</button>
          </div>
          <div class="modal-video d-flex justify-content-center">
            <iframe
                class="video_frame"
                style="width: 80%; height: 80vh; border-radius: 20px; z-index: 2; "
                :src="youtubeEmbedUrl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
          </div>
        </div>
      </dialog>
    </div>
    <swiper
        :slidesPerView="'auto'"
        :centeredSlides="true"
        :spaceBetween="30"
        :rewind="true"
        :navigation="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :modules="modules"
        class="mySwiper"
        :initialSlide="1"
        :breakpoints="{
          768: {
            slidesPerView: 'auto',
            spaceBetween: 30,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <div id="carouselExampleCaptions" class="carousel slide w-100">
          <div class="carousel-inner w-100 h-100">
            <div class="carousel-item active position-relative w-100">
              <div class="gradient"></div>
              <img :src="slide.mob_imageSrc" class="d-block d-md-none w-100 rounded-4" alt="...">
              <img :src="slide.imageSrc" class="d-md-block d-none w-100 rounded-4" alt="...">
              <div class="carousel-caption">
                <h3 class="top-text-head" :style="{ color: slide.title_color }">{{ slide.title }}</h3>
                <p class="top-text-description d-sm-block d-none" style="width: 90%;">{{ slide.description }}</p>
                <div class="d-flex justify-content-between align-items-center">
                  <a class="more_details" :href="slide.link">{{ slide.moreDetailsText }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M8.41797 3.45898L11.9588 6.99982L8.41797 10.5407" stroke="white" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2.04297 7H11.8605" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                  <div role="button" class="play_image" @click="openModal(slide.videoUrl)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
                      <rect width="67" height="67" rx="33.5" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67ZM31.6353 21.6408C30.3503 20.7842 28.6982 20.7043 27.3366 21.433C25.975 22.1617 25.125 23.5807 25.125 25.125V41.875C25.125 43.4193 25.975 44.8383 27.3366 45.567C28.6982 46.2957 30.3503 46.2159 31.6353 45.3592L44.1978 36.9842C45.3628 36.2076 46.0625 34.9001 46.0625 33.5C46.0625 32.0999 45.3628 30.7924 44.1978 30.0158L31.6353 21.6408Z"
                            fill="url(#paint0_linear_837_528)"/>
                      <defs>
                        <linearGradient id="paint0_linear_837_528" x1="9.38" y1="11.6648" x2="64.9468" y2="36.4126"
                                        gradientUnits="userSpaceOnUse">
                          <stop stop-color="#1AE5FE"/>
                          <stop offset="1" stop-color="#00B9D0"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {
      isModalOpen: false,
      currentVideoUrl: '',
      slides: [
        {
          title: 'Smart Classroom',
          description: 'A large touchscreen displays lessons, apps, and videos. With touch, whiteboarding, and internet, it makes learning fun. It also connects easily with other classroom tools for interactive lessons.',
          imageSrc: 'https://artive.com.bd/media/images/WEB_Home_Solution_3.webp',
          mob_imageSrc: 'https://artive.com.bd/media/images/MOB_Solution1.webp',
          moreDetailsText: 'More details',
          link: '/solutions/?label=Smart Classroom Solution',
          title_color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          title: 'Audio-Visual',
          description: 'Creating, producing and distributing contents to publish over the IP Network for effectively convey a message, tell a story, or share information using combination of sound, visuals and texts.',
          imageSrc: 'https://artive.com.bd/media/images/WEB_Home_Solution_2.webp',
          mob_imageSrc: 'https://artive.com.bd/media/images/MOB_Solution_2.webp',
          moreDetailsText: 'More details',
          link: '/solutions/?label=Audio Visual',
          title_color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
        {
          title: 'Smart Conference',
          description: 'The solution ensures office like working environment at remote working places featuring crystal clear audio, video and intelligent microphone.',
          imageSrc: 'https://artive.com.bd/media/images/WEB_Home_Solution_1.webp',
          mob_imageSrc: 'https://artive.com.bd/media/images/MOB_Solution_3.webp',
          moreDetailsText: 'More details',
          link: '/solutions/?label=Smart Conference',
          title_color: '#FFFFFF',
          videoUrl: 'https://www.youtube.com/watch?v=0A1nD9h3-2s'
        },
      ],
    };
  },
  computed: {
    youtubeEmbedUrl() {
      return this.currentVideoUrl.replace("watch?v=", "embed/");
    },
  },
  methods: {
    openModal(videoUrl) {
      this.currentVideoUrl = videoUrl;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.top-solution {
  padding: 80px;
  padding-bottom: 120px;
}

.top-solution-header {
  margin-bottom: 40px;
}

.top-solution-header h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 59.52px */
  text-transform: capitalize;
  margin-bottom: 0;
}

/*.swiper-slide {*/
/*  width: 980px !important;*/
/*}*/
.mySwiper {
  width: 100%;
  padding: 20px 0;
  height: fit-content;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70% !important; /* Centered slide width */
}
/* .swiper-slide-prev, .swiper-slide-next{
    opacity: 0.8;
} */
.gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1D1C1C 4.5%, rgba(30, 30, 30, 0.50) 33.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.carousel-item img {
  width: 100%;
}

.carousel-caption {
  right: 8%;
  left: 5%;
  bottom: 5%;
  text-align: start;
}

.top-text-head {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.top-text-description {
  color: #FFF;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.more_details {
  color: #FFF;
  text-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  border-radius: 34px;
  border: 1px solid #797979;
  background: linear-gradient(180deg, #525252 0%, #2A2B2C 100%);
  padding: 12px 24px;
}

.more_details svg {
  margin-left: 10px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px !important;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .top-solution-header h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .top-solution {
    padding: 20px;
  }

  .top-solution-header {
    margin-bottom: 20px;
  }

  .mySwiper {
    padding: 20px 0;
  }
  .top-text-head {
    font-size: 1.125rem;
  }
  .video_frame{
    height: 24vh !important;
  }
  .close-modal-btn{
    margin-right: 1rem !important;
  }
}

@media only screen and  (max-width: 768px) {
  .mySwiper {
    height: fit-content !important;
  }
  .swiper-slide {
    width: 100% !important;
  }
  .carousel-caption {
    bottom: 0;
  }
  .more_details {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 400;
  }

  .play_image svg {
    width: 2.5rem !important;
  }

  .more_details svg {
    margin-left: 0;
  }

  .top-solution-header h2 {
    color: #1E1E1E;
    text-align: center;
    font-size: 28px;
  }
   .video_frame{
    height: 43vh !important;
  }
   .close-modal-btn{
    margin-right: 1rem !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .mySwiper {
    height: fit-content;
  }
   .video_frame{
    height: 53vh !important;
  }
   .close-modal-btn{
    margin-right: 1rem !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .top-solution {
    padding: 20px;
  }
  .top-solution-header h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 59.52px */
  text-transform: capitalize;
  margin-bottom: 0;
}


}

</style>