<template>
  <div class="Artiv">
    <div class="innovative">
      <h1>innovative product highlights</h1>
      <div class="d-none d-lg-block">
        <table>
          <tr>
            <td rowspan="3" colspan="2">
              <a href="/products/Smart%20Board/#product-categories">
                <div class="five text-center w-100">
                  <h2>Interactive Flat Panel</h2>
                  <div class="py-5">
                    <img src="https://artive.com.bd//media/images/Frame_1000002177.png" alt="">
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="/products/Wireless%20Receive/#product-categories">
                <div class="third text-center w-100">
                  <h2>UHF Wireless Receiver</h2>
                  <div class="py-5">
                    <img src="https://artive.com.bd/media/images/Wireless_receiver.webp" alt="">
                  </div>
                </div>
              </a>
            </td>
          </tr>
           <tr>
            <td rowspan="3">
              <a href="#">
                <div class="four text-center w-100">
                  <h2>Mobile Stand</h2>
                  <div class="py-5">
                    <img src="./Artives-Innovative-Product-image/Image_20231002093104.png" alt="">
                  </div>
                </div>
              </a>
            </td>

          </tr>
           <tr>
            <td rowspan="2">
              <a href="/products/Digital%20Signage/#product-categories">
                <div class="first text-center w-100 ">
                  <h2>Digital Signage</h2>
                  <div class="py-5">
                    <img src="./Artives-Innovative-Product-image/Group 1000001833 1.png" alt="">
                  </div>
                </div>
              </a>
            </td>
            <td rowspan="2">
              <a href="/products/Mini%20Projector/#product-categories">
                <div class="second text-center w-100 ">
                  <h2>Portable Projector</h2>
                  <div class="py-5">
                    <img src="https://artive.com.bd/media/images/Mini_Projector_bMwxH0q.webp" alt="">
                  </div>
                </div>
              </a>
            </td>

          </tr>
        </table>
      </div>

      <div class="d-block d-lg-none">
        <div class="w-100" style="margin: 0.5rem 0">
          <a href="/products/Smart%20Board/#product-categories">
            <div class="five text-center w-100">
              <h2>Interactive Flat Panel</h2>
              <div class="py-3 py-lg-5 ">
                <img src="https://artive.com.bd//media/images/Frame_1000002177.png" alt="">
              </div>
            </div>
          </a>
        </div>
        <div class="w-100 d-flex justify-content-between gap-2" style="margin: 0.5rem 0">
          <div class="w-50">
            <a href="/products/Digital%20Signage/#product-categories">
              <div class="first text-center w-100 ">
                <h2>Digital Signage</h2>
                <div class="py-3 py-lg-5 ">
                  <img src="./Artives-Innovative-Product-image/Group 1000001833 1.png" alt="">
                </div>
              </div>
            </a>
          </div>
          <div class="w-50">
            <a href="/products/Mini%20Projector/#product-categories">
              <div class="second text-center w-100 ">
                <h2>Portable Projector</h2>
                <div class="py-3 py-lg-5 ">
                  <img src="./Artives-Innovative-Product-image/Picture1 3.png" alt="">
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-between gap-2">
          <div class="w-50">
            <a href="/products/Wireless%20Receive/#product-categories">
              <div class="third text-center w-100">
                <h2>UHF Wireless Receiver</h2>
                <div class="py-3 py-lg-5 ">
                  <img src="https://artive.com.bd/media/images/Wireless_receiver.webp" alt="">
                </div>
              </div>
            </a>
          </div>
          <div class="w-50">
            <a href="/product/premium-stand">
              <div class="four text-center w-100">
                <h2>Mobile Stand</h2>
                <div class="py-3 py-lg-5 ">
                  <img src="./Artives-Innovative-Product-image/Image_20231002093104.png" alt="">
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnovativeProduct"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.innovative {
  padding: 80px;
}

.innovative h1 {
  text-align: center;
  margin-bottom: 50px;
  color: #1E1E1E;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  /* 59.52px */
  text-transform: capitalize;
}

table {
  border-spacing: 30px;
  /* Creates a gap between table cells */
  width: 100%;
}

td {
  padding: 15px;
}

.first,
.second,
.third,
.four,
.five {

  padding: 50px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 15px;

}

.first h2,
.second h2,
.third h2,
.four h2,
.five h2 {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
  /* 30.72px */
  letter-spacing: 1.2px;

}

.five img {
  transition: 0.5s;
  width: 650px;
  height: 350px;
}

.five:hover {
  background: url(./Artives-Innovative-Product-image/Frame\ 1000002099.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.five:hover img {
  transform: scale(1.1);
}

.first img {
  transition: 0.5s;
  width: 300px;
  height: 300px;
}

.first:hover {
  background: url(./Artives-Innovative-Product-image/Frame\ 1000002099.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.first:hover img {
  transform: scale(1.1);

}

.second img {
  transition: 0.5s;
  width: 300px;
  height: 300px;
}

.second:hover {
  background: url(./Artives-Innovative-Product-image/Frame\ 1000002099.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.second:hover img {
  transform: scale(1.1);
}

.third img {
  transition: 0.5s;
  width: 200px;
  height: 200px;
}

.third:hover {
  background: url(./Artives-Innovative-Product-image/Frame\ 1000002099.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.third:hover img {
  transform: scale(1.1);
}

.four img {
  transition: 0.5s;
  width: 300px;
  height: 450px;
}

.four:hover {
  background: url(./Artives-Innovative-Product-image/Frame\ 1000002099.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.four:hover img {
  transform: scale(1.1);
}


.first {
  background-image: url(./Artives-Innovative-Product-image/Frame\ 1000002077.png);
  height: 464px;
}

.second {
  background-image: url(./Artives-Innovative-Product-image/two.png);
  height: 464px;
}

.third {
  background-image: url(./Artives-Innovative-Product-image/Frame\ 1000002077.png);
  height: 343px;
}

.four {
  background-image: url(./Artives-Innovative-Product-image/five.png);
  height: 637px;
}

.five {
  background-image: url(./Artives-Innovative-Product-image/three.png);
  height: 517px;
}
@media only screen and (max-width: 576px) {
  .innovative {
    padding: 20px;
  }

  .five img {
    transition: 0.5s;
    width: 95%;
    height: 250px;
  }

  .first img {
    transition: 0.5s;
    width: 100%;
    height: 200px;
  }

  .second img {
    transition: 0.5s;
    width: 100%;
    height: 200px;
  }

  .third img {
    transition: 0.5s;
    width: 100%;
    height: 200px;
  }

  .four img {
    transition: 0.5s;
    width: 100%;
    height: 200px;
  }

  .first,
  .second,
  .third,
  .four,
  .five {
    height: 350px;

  }

  .five:hover img {
    transform: scale(1.1);
  }

  .first h2,
  .second h2,
  .third h2,
  .four h2,
  .five h2 {
    font-size: 14px;

  }

  .innovative h1 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {

  .innovative {
    padding: 20px;
  }

  .five img {
    transition: 0.5s;
    width: fit-content;
    height: 300px;
  }

  .first img {
    transition: 0.5s;
    width: fit-content;
    height: 200px;
  }

  .second img {
    transition: 0.5s;
    width: fit-content;
    height: 250px;
  }

  .third img {
    transition: 0.5s;
    width: fit-content;
    height: 250px;
  }

  .four img {
    transition: 0.5s;
    width: fit-content;
    height: 250px;
  }

  .first,
  .second,
  .third,
  .four,
  .five {
    height: 400px;

  }

  .five:hover img {
    transform: scale(1.1);
  }

  .first h2,
  .second h2,
  .third h2,
  .four h2,
  .five h2 {
    font-size: 16px;

  }

  .innovative h1 {
    font-size: 28px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .innovative {
    padding: 20px;
  }

  .five img {
    transition: 0.5s;
    width: 600px;
    height: 350px;
  }

  .first img {
    transition: 0.5s;
    width: 250px;
    height: 250px;
  }

  .third img {
    transition: 0.5s;
    width: 180px;
    height: 180px;
  }

  .four img {
    transition: 0.5s;
    width: 250px;
    height: 400px;
  }
}
</style>