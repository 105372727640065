<template>
  <div class="artive d-flex justify-content-between" :style="{ backgroundImage: `url(${currentImage})` }">
    <div class="content d-flex">
      <div class="text-start" :style="{ color: `${color}` }">
        <h1 class="about-top-head">{{ title }}</h1>
        <p class="about-top-descrip" style="width: 60%;">{{ description }}</p>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductMainsection",
  props: ['title', 'description', 'desktopImage', 'mobileImage', 'color'],
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    currentImage() {
      return this.windowWidth < 768 ? this.mobileImage : this.desktopImage;
    }
  },
  created() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>


a {
  text-decoration: none;
}

.about-top-head {
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 76.8px */
  text-transform: capitalize;
}

.content {
  width: 50%;
  margin: 0 80px;
}


.artive {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 220px 0;
  width: 100%;
  position: relative;
  height: 80vh;
}

.about-top-descrip {
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .content {
    margin: 20px;
    width: 100%;
  }

  .about-top-descrip {
    color: #1E1E1E;
    text-align: justify;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
    width: 100% !important;
  }

  .artive {

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0;
    width: 100%;
    position: relative;
    height: 80vh;
  }


}

@media only screen and (min-width: 577px) and (max-width: 991px) {

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .content {
    margin: 20px;
  }

  .about-top-descrip{
    color: #1E1E1E;
    text-align: justify;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
    width: 100% !important;
  }

  .artive {
    background: url("./Artives-Hero/MOB_Home_Hero1.webp");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 280px 0;
    width: 100%;
    position: relative;
    height: 80vh;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .content {
    margin: 20px;
  }
}
</style>