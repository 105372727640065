<template>
  <div class="smart-classroom" style="margin-bottom: 5rem">
    <div class="about-top">
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-inner first_img">
          <div class="w-100 h-100 position-relative">
            <img src="https://artive.com.bd/media/images/WEB__Smart_Classroom.webp" alt="..."
                 class="d-none d-md-block w-100 h-100" style="object-fit: cover;">
            <img src="https://artive.com.bd/media/images/MOB_Smart_Classroom_1.webp"
                 class="d-block d-md-none w-100 h-100" alt="..." style="object-fit: cover;">
            <div class="carousel-caption carousel_caption_one">
              <h1 class="about-top-head">SMART CLASSROOM SOLUTION
              </h1>
              <p class="about-top-descrip">Superior stability and durability, crafted with high-quality materials for
                long-lasting performance.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="smart_classroom">
        <div class="w-100 w-lg-50">
          <h2 class="smart_modern">Smart Solutions for Modern Classrooms</h2>
        </div>
        <div class="w-100 w-lg-50">
          <p class="smart_modern_description">The SMART Board (Interactive Flat Panel) enhances smart
            classrooms with its large touchscreen, interactive features, and digital whiteboarding. It
            displays educational content and multimedia, boosting engagement and collaboration. Integrated
            with other classroom tech, it makes lessons more interactive and effective.</p>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center">
        <div class="w-100 w-lg-50 second_img">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/Smart_classroom1.webp"
               alt="">
        </div>
        <div class="w-100 w-lg-50 auto_track" >
          <div class="disc" style="padding: 80px;">
            <h2 class="more_edu_head">Making Education More Engaging</h2>
            <p class="more_edu_descrip">Interactive tools like digital displays to create a more dynamic and
              immersive learning environment, enhancing student participation and collaboration.</p>
          </div>
        </div>
      </div>
      <div class="more_edu d-flex align-items-center  flex-column-reverse flex-lg-row">
        <div class="w-100 w-lg-50 classroom" >
          <div class="disc" style="padding: 80px;">
            <h2 class="more_edu_head">Making Education More Engaging</h2>
            <p class="more_edu_descrip">Interactive tools like digital displays to create a more dynamic and
              immersive learning environment, enhancing student participation and collaboration.</p>
          </div>
        </div>
        <div class="w-100 w-lg-50  wireless">
          <img class="w-100 h-100" src="https://artive.com.bd/media/images/WEB_Smart_Classroom_3_ICKoNyk.webp"
               alt="">
        </div>

      </div>
      <div class="classroom d-grid" style="padding: 5rem; gap: 5rem">
        <div class="d-flex align-items-center flex-column flex-lg-row" style="gap: 5rem;">
          <div class="w-100 w-lg-50 last_img">
            <img class="w-100 h-100"
                 src="https://artive.com.bd/media/images/smart-classroom-sub-1_lgLdAIw.png"
                 alt="">
          </div>
          <div class="w-100 w-lg-50">
            <h2 class="class_head">Small Classroom</h2>
            <p class="class_descrip">These solutions typically include interactive displays, compact
              projectors, and portable audio systems that fit well in limited spaces.</p>
            <ul class="class_ul">
              <li>Engage students with interactive screens.</li>
              <li>Clear visuals in small spaces.</li>
              <li>Keeps students involved and interested.</li>
            </ul>
          </div>
        </div>
        <div class="d-flex align-items-center flex-column-reverse flex-lg-row" style="gap: 80px;">
          <div class="w-100 w-lg-50">
            <h2 class="class_head">Medium Classroom</h2>
            <p class="class_descrip">Enhances learning with interactive displays, clear sound systems, and wireless
              content
              sharing. It supports various teaching styles and ensures easy, effective collaboration.</p>
            <ul class="class_ul">
              <li>Flexible setup for various teaching styles</li>
              <li>Clear sound systems to ensure everyone hears</li>
              <li>Wireless content sharing for seamless collaboration</li>
            </ul>
          </div>

          <div class="w-100 w-lg-50 last_img">
            <img class="w-100 h-100"
                 src="https://artive.com.bd/media/images/smart-classroom-sub-2.png"
                 alt="">
          </div>

        </div>
        <div class="d-flex align-items-center flex-column flex-lg-row" style="gap: 80px;">
          <div class="w-100 w-lg-50 last_img">
            <img class="w-100 h-100"
                 src="https://artive.com.bd/media/images/smart-classroom-sub-3.png"
                 alt="">
          </div>
          <div class="w-100 w-lg-50">
            <h2 class="class_head">Large Classroom</h2>
            <p class="class_descrip">These solutions typically include interactive displays, compact projectors, and
              portable
              audio systems that fit well in limited spaces.</p>
            <ul class="class_ul">
              <li>Engage students with interactive screens.</li>
              <li>Clear visuals in small spaces.</li>
              <li>Affordable and effective.</li>
              <li>Connects easily with existing tech.</li>
              <li>Keeps students involved and interested.</li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SmartClassroom"
}
</script>

<style scoped>

a {
  text-decoration: none;
}
.carousel-inner {
  height: 810px;
}



.about-top-head {
  color: #1E1E1E;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 76.8px */
  text-transform: capitalize;
}


.carousel_caption_one {
  position: absolute;
  top: 25%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
  width: 500px;
  margin-bottom: 15px;
  height: fit-content;
  left: 4.8%;
}

.about-top-descrip {
  color: var(--Text-color, #1E1E1E);
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.smart_classroom {
  padding: 80px;
  gap: 80px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
}



.smart_modern {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  width: 500px;
}

.smart_modern_description {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.more_edu_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.more_edu_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_head {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 51.2px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.class_descrip {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.class_ul li {
  color: #1E1E1E;
  text-align: justify;
  text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 228.571% */
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 576px) {
  .carousel_caption_one {
    font-size: 24px;
  }
  .disc{
  padding: 20px !important;
}

  .more_edu_head {
    font-size: 24rem;
  }

  .smart_modern {
    text-align: center;
  }

  .carousel_caption_one {
    position: absolute;
    top: 25%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
    width: 90%;
  }

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }

  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 24px;
  }

  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
    text-align: center;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 24px;
  }

  .last_img {
    height: fit-content !important;
  }
  .carousel-inner {
    height: 600px;
  }
}


@media only screen and (min-width: 577px) and (max-width: 991px) {
  .carousel_caption_one {
    position: absolute;
    top: 25%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
    margin-bottom: 15px;
  }
  .disc{
  padding: 40px !important;
}

  .about-top-head {
    color: #1E1E1E;
    text-shadow: 0px 30px 60px rgba(57, 57, 57, 0.10);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: capitalize;
  }

  .smart_classroom {
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    flex-direction: column;
  }

  .smart_modern {
    color: #1E1E1E;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.5px;
    width: fit-content;
  }

  .more_edu {
    flex-direction: column;
  }

  .auto_track {
    padding: 20px !important;
    text-align: center;
  }

  .more_edu_head {
    color: #1E1E1E;
    font-size: 28px;
  }


  .wireless {
    padding: 20px !important;
    text-align: center;
  }

  .classroom {
    padding: 20px !important;
    text-align: center;
  }

  .five_img {
    margin-bottom: 20px !important;
  }

  .main_class {
    width: fit-content !important;
  }

  .class_head {
    color: #1E1E1E;
    font-size: 28px;
  }

.carousel_caption_one {

  left: 2.8%;
}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .carousel_caption_one {

  left: 2.8%;
}
}
</style>
