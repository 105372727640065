<template>
  <div class="product-showcase">
    <div class="text-center">
      <h2 class="product-showheader">Our Product Showcase</h2>
    </div>
    <!-- Swiper -->
    <swiper class="mySwiper container"
            :slides-per-view="1"
            :space-between="30"
            :pagination="{ clickable: true }"
            :breakpoints="{
        576: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1600: {
          slidesPerView: 4,
        }
      }">
      <swiper-slide v-for="(product, index) in productsData" :key="index" class="card"
                    style="border: 1px solid transparent; border-radius: 0px 0px 0.75rem 0.75rem;">
        <a :href="`/product/${product.slug}`">
          <div class="card_img">
            <img :src="product.image" class="card-img-top" alt="Product Image" height="166" width="276">
          </div>
          <div class="card-body w-100">
            <div class="d-flex align-items-center justify-content-between card_body_header">
              <h2 class="mb-0">{{ product.title }}</h2>
              <a :class="product.status">{{ product.status }}</a>
            </div>
            <div class="d-flex align-items-center justify-content-between card_body_footer">
              <p class="mb-0">{{ getCategoryTitle(product.category) }}</p>
              <div class="d-flex gap-2 card_body_custom">
                <a v-for="(size, sizeIndex) in product.sizes" :key="sizeIndex">{{ size }}</a>
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';


export default {
  name: "ProductShowcase",
  props: ['products', 'categories'],

  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productsData: JSON.parse(this.products),
      categoriesData: JSON.parse(this.categories),
    };
  },
  methods: {
    getCategoryTitle(categoryId) {
      const category = this.categoriesData.find(cat => cat.id === categoryId);
      return category ? category.title : 'Unknown';
    },
  },
};
</script>

<style scoped>
html,
body {
  position: relative;
  height: 100%;
}

a {
  text-decoration: none;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.card_img {
  background-image: url("./Artives-Product-Showcase-image/image-bg.png");
}

.product-showcase {
  width: 100%;
}

.mySwiper {
  width: 100%;
}

.card {
  color: white;
  background: #1565C0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.card_img img {
  display: block;
  width: 100%;
}

.card {
  /*width: fit-content !important;*/
  border-radius: 12px;
  background: #F9F9FB;
  box-shadow: 105px 123px 45px 0px rgba(110, 110, 110, 0.00), 67px 79px 41px 0px rgba(110, 110, 110, 0.01), 38px 44px 35px 0px rgba(110, 110, 110, 0.03), 17px 20px 26px 0px rgba(110, 110, 110, 0.04), 4px 5px 14px 0px rgba(110, 110, 110, 0.05);
  height: fit-content;
}

.card_img {
  padding: 40px;
  width: 100%;
}

.card_img img {
  width: 100%;
}

.card-body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card_body_header h2 {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.card_body_header .for_popular {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #FC9B00;
  box-shadow: 0px 0px 14px 1px rgba(252, 155, 0, 0.50);
}

.card_body_header .for_new {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #34C759;
  box-shadow: 0px 0px 14px 1px rgba(52, 199, 89, 0.50);
}

.card_body_header .for_upcomming {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  padding: 5px 12px;
  border-radius: 5px;
  background: #565758;
  box-shadow: 0px 0px 14px 1px rgba(125, 125, 125, 0.50);
}

.card_body_footer p {
  color: #848E99;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.56px;
  /* 179.667% */
  text-transform: capitalize;
}

.card_body_custom a {
  color: #848E99;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.25px;
  /* 143.75% */
  border-radius: 6px;
  border: 1px solid #C7D0D9;
  padding: 4px;
}

.mySwiper {
  padding: 60px 0;
  height: fit-content;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px;
  height: 50px;
  color: gray;
  border-radius: 50px;
  border: 1px solid #848484;
  background: #FFF;
  box-shadow: 0px 6px 12px 0px rgba(201, 203, 208, 0.25);
}

.swiper-button-next {
  right: 5px;
}

.swiper-button-prev {
  left: 5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px;
  font-weight: 600;

}

.product-showheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  align-self: stretch;
  text-align: center;
  font-size: 48px;
  line-height: 1;

}

.product-showcase {
  padding-top: 80px;
}

@media only screen and (max-width: 576px) {
  .product-showcase {
    padding-top: 40px;
  }
  .product-showheader{
   font-size: 24px;
  }
  .mySwiper{
    padding: 20px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .card_img {
    padding: 20px;
    width: 100%;
  }

  .card-body {
    padding: 20px;
  }

  .card_body_header h2 {
    font-size: 14px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 12px;
    font-weight: 600;
  }

  .swiper-button-prev {
    left: 6px;
  }

  .swiper-button-next {
    right: 6px;
  }

  .mySwiper {
    padding: 40px;
    height: fit-content;
  }
}
</style>