import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {BootstrapVueNext} from 'bootstrap-vue-next';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import { VueReCaptcha } from 'vue-recaptcha-v3';


import HelloWorld from './components/HelloWorld.vue'
import AboutUs from "@/components/AboutUs";
import AllProduct from "@/components/AllProduct";
import ArtiveFooter from "@/components/ArtiveFooter";
import ArtivesNav from "@/components/ArtivesNav";
import BusinessEducation from "@/components/BusinessEducation";
import ContactUs from "@/components/ContactusFaq";
import HeroArea from "@/components/HeroArea";
import InnovativeProduct from "@/components/InnovativeProduct";
import HighQuality from "@/components/HighQuality";
import HomeContactus from "@/components/HomeContactus";
import HybridClassroom from "@/components/HybridClassroom";
import InteractivePanel from "@/components/InteractivePanel";
import ProductAccordion from "@/components/ProductAccordion";
import ProductCard from "@/components/ProductCard";
import ProductMainsection from "@/components/ProductMainsection";
import ProductMainsubsection from "@/components/ProductMainsubsection";
import ProductNavbar from "@/components/ProductNavbar";
import ProductShowcase from "@/components/ProductShowcase";
import ProductSpecification from "@/components/ProductSpecification";
import ProductSubone from "@/components/ProductSubone";
import ProductSubtwo from "@/components/ProductSubtwo";
import ProductSubthree from "@/components/ProductSubthree";
import ProductSubfour from "@/components/ProductSubfour";
import ProductSubfourslide from "@/components/ProductSubfourslide";
import ProductSubsix from "@/components/ProductSubsix";
import ProductSubfive from "@/components/ProductSubfive";
import ProductSubseven from "@/components/ProductSubseven";
import SmartClassroom from "@/components/SmartClassroom";
import SmartProduct from "@/components/SmartProduct";
import TopSolutions from "@/components/TopSolutions";
import AudioVisual from "@/components/AudioVisual";
import SmartConference from "@/components/SmartConference";
import PaperlessClassroom from "@/components/PaperlessClassroom";
import ProductTabaccordion from "@/components/ProductTabaccordion";
import ProductFeaturesslider from "@/components/ProductFeaturesslider";
import DownLoad from '@/components/DownLoad'
const app = createApp({})

app.use(VueReCaptcha, {
  siteKey: '6Lfo0HEqAAAAAGHTx6raTlSVvW4bBWvP8ybkaTXf', // Replace with your actual site key from Google 6Lfo0HEqAAAAAG_5K6XcsrCH92AyCQ1cFol_3Rku
  loaderOptions: {
    autoHideBadge: true, // Optionally, hide the badge
  },
});

app.component('hello-world', HelloWorld)
app.component('about-us', AboutUs);
app.component('all-product', AllProduct);
app.component('artive-footer', ArtiveFooter);
app.component('artives-nav', ArtivesNav);
app.component('business-education', BusinessEducation);
app.component('contact-us', ContactUs);
app.component('hero-area', HeroArea);
app.component('innovative-product', InnovativeProduct);
app.component('high-quality', HighQuality);
app.component('home-contactus', HomeContactus);
app.component('hybrid-classroom', HybridClassroom);
app.component('interactive-panel', InteractivePanel);
app.component('product-accordion', ProductAccordion);
app.component('product-card', ProductCard);
app.component('product-mainsection', ProductMainsection);
app.component('product-mainsubsection', ProductMainsubsection);
app.component('product-navbar', ProductNavbar);
app.component('product-showcase', ProductShowcase);
app.component('product-specification', ProductSpecification);
app.component('product-subone', ProductSubone);
app.component('product-subtwo', ProductSubtwo);
app.component('product-subthree', ProductSubthree);
app.component('product-subfour', ProductSubfour);
app.component('product-subfourslide', ProductSubfourslide);
app.component('product-subsix', ProductSubsix);
app.component('product-subfive', ProductSubfive);
app.component('product-subseven', ProductSubseven);
app.component('smart-classroom', SmartClassroom);
app.component('smart-product', SmartProduct);
app.component('top-solutions', TopSolutions);
app.component('audio-visual', AudioVisual);
app.component('smart-conference', SmartConference);
app.component('paperless-classroom', PaperlessClassroom);
app.component('product-tabaccordion', ProductTabaccordion);
app.component('product-featuresslider', ProductFeaturesslider);
app.component('down-load', DownLoad)
// Make BootstrapVue available throughout your project
app.use(BootstrapVueNext);
// Optionally install the BootstrapVue icon components plugin

app.mount('body')