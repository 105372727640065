<template>
  <div class="Artiv">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
            class="nav-link"
            :class="{ active: activeTab === 'home' }"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="activeTab === 'home'"
            @click="setActiveTab('home')"
        >
          Corporate
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
            class="nav-link"
            :class="{ active: activeTab === 'profile' }"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="activeTab === 'profile'"
            @click="setActiveTab('profile')"
        >
          Education
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
            class="tab-pane"
            :class="{ show: activeTab === 'home', active: activeTab === 'home' }"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
            v-if="activeTab === 'home'"
        >
          <div class="two-img w-100">
            <div id="carouselExampleCaptions1" class="carousel slide w-100 h-100">
              <div class="carousel-inner w-100 h-100">
                <div class="carousel-item position-relative active w-100">
                  <h2 class="position-absolute d-none d-lg-block Busness_edu_header" style="bottom: 4rem; color: white; z-index: 1;">Corporate Solutions with
                    Interactive Displays
                  </h2>
                  <div class="business-img w-100 h-100"
                       style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px;"></div>
                  <img class="d-block w-100 h-100" style="border-radius: 20px;"
                       src="https://artive.com.bd/media/images/Home_confercence.webp" alt="Business Image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
            class="tab-pane"
            :class="{ show: activeTab === 'profile', active: activeTab === 'profile' }"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
            v-if="activeTab === 'profile'"
        >
          <div class="two-img w-100">
            <div id="carouselExampleCaptions2" class="carousel slide w-100 h-100">
              <div class="carousel-inner w-100 h-100">
                <div class="carousel-item position-relative active w-100" style="min-height:25vw">
                   <h2 class="position-absolute d-none d-lg-block Busness_edu_header" style="bottom: 4rem; color: white; z-index: 1;">Making Learning Engaging and Effective
                  </h2>
                  <div class="business-img w-100 h-100"
                       style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px;"></div>
                  <img class="d-block w-100 h-100" style="border-radius: 20px;"
                       src="https://artive.com.bd/media/images/education2.webp" alt="Education Image">
                  <div class="carousel-caption">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'ArtivComponent',
  setup() {
    const activeTab = ref('home');

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    return {
      activeTab,
      setActiveTab
    };
  }
};
</script>

<style scoped>
.tab-content {
  position: relative;
  overflow: hidden;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-leave, .slide-fade-enter-to /* .slide-fade-enter-active in <2.1.8 */
{
  transform: translateX(0);
  opacity: 1;
}
</style>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel-item .Busness_edu_header {
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  left: 55px;
}

a {
  text-decoration: none;
}

.business-img {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1D1C1C 0%, rgba(30, 30, 30, 0.50) 23.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
}

.gradient-2 {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #ff0000 4.5%, rgba(52, 134, 5, 0.5) 33.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.Artiv {
  padding: 80px;
  padding-top: 120px;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 5.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
}

.business_card_text {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
}

.nav-pills {
  display: flex;
  width: fit-content;
  margin: auto;
  border-radius: 55px;
  padding: 5px;
  border: 1px solid #DADAD9 !important;
}

.two-img {
  height: fit-content;
}

.nav-link {
  border-radius: 55px !important;
  transition: 0.5s;
  color: #070707;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  text-transform: capitalize;
  padding: 12px 20px;
}

.nav-pills {
  margin-bottom: 40px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {

  color: var(--bs-nav-pills-link-active-color);
  background: #1E1E1E;
}

@media only screen and (max-width: 576px) {
  .Artiv {
    padding: 20px;
    padding-top: 0;
  }

  .business_card_text {
    font-size: 18px;
  }

  .two-img {
    height: fit-content;
  }

  .nav-link {
    border-radius: 55px !important;
    font-size: 14px;
    padding: 6px 14px;
  }
}


@media only screen and (min-width: 577px) and (max-width: 991px) {
  .business_card_text {

    font-size: 22px;
  }

  .two-img {
    height: fit-content;
  }

  .Artiv {
    padding: 40px;
    padding-top: 0;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
</style>