<template>
  <div class="connected d-none d-md-block container-fluid w-100">
    <div class="row align-items-center connect">
      <div class="col-6">
        <h2>Get Connected With Us</h2>
        <p class="des">We are here to upgrade your system. Our smart solutions make your meetings and learning more
          productive and
          efficient.</p>
        <a href="/contact-us/" class="contact_btn">Contact Us</a>
      </div>
    </div>
  </div>
  <div class="connected d-md-none d-block">
    <div class="top">
      <h2>Get Connected With Us</h2>
      <p class="des">We are here to upgrade your system. Our smart solutions make your meetings and learning more
        productive
        and efficient.</p>
      <a href="/contact-us/" class="contact_btn">Contact Us</a>
    </div>

  </div>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>
.connected {
  background: url('https://artive.com.bd/media/images/wEB_CONTACT.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.connected {
  padding: 120px 80px;
  height: 80vh;
}

.des {
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 28px;
  font-size: 14px;
}

.left h2 {
  margin-bottom: 0.75rem;
}
.left p {
  margin-bottom: 2.25rem;
}
.right img {
  width: 100%;
}
.home-contact-us {
  padding: 1.25rem;
}
.home-contact-us .top {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
}
.home-contact-us .top a, .left a {
  width: fit-content;
}

.contact_btn {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 400;
  border-radius: 34px;
  background: #FF3D24;
  padding: 8px 16px;
  text-decoration: none;
}

@media only screen and (max-width: 576px) {
  .connected {
    background: url('https://artive.com.bd/media/images/mob_CONTACT.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 90px 30px;
    height: 100vh;
  }

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .home-contact-us {

  }

  .contact_btn {
    font-size: 1rem;
  }

  .right img {
    width: 26.25rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
</style>