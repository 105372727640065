<template>
  <div>
    <swiper
        :slidesPerView="2"
        :spaceBetween="80"
        :pagination="{ clickable: true }"
        :modules="modules"
        :breakpoints="{
        1024: { slidesPerView: 2, spaceBetween: 80 },
        768: { slidesPerView: 1, spaceBetween: 40 },
        0: { slidesPerView: 1, spaceBetween: 20 }
      }"
        class="mySwiper"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <div class="d-flex flex-column justify-content-between align-center">
          <div class="w-100">
            <img :src="require(`@/assets/${slide.image}`)" alt="Product image" class="w-100">
          </div>
          <div class="text-center">
            <h4>{{ slide.text }}</h4>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';

export default {
  name: 'ProductFeaturesslider',
  components: {Swiper, SwiperSlide},
  props: {
    content: {
      type: String,
      default: JSON.stringify([
        {image: 'Rectangle 4245.png', text: 'hello'},
        {image: 'Rectangle 4244.png', text: 'hello'},
        {image: 'Rectangle 4245.png', text: 'hello'},
        {image: 'Rectangle 4244.png', text: 'hello'},
        {image: 'Rectangle 4245.png', text: 'hello'},
        {image: 'Rectangle 4244.png', text: 'hello'}
      ])
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup() {
    const slides = JSON.parse(this.content);
    return {slides, modules: [Pagination]};
  }
};
</script>
<style scoped>
@media only screen and (max-width: 991px) {
  .mySwiper {
    padding: 40px !important;
  }
}
</style>