<template>
  <div class="wrapper">
    <div class="header">
      <h2>Smart Products Make Smarter</h2>
    </div>
    <div class="cards">
      <div class="card smart-card" v-for="(card, index) in cards" :key="index">
        <div :class="['image-' + (index + 1), 'ps-5']">
          <div class="smart_product_text" style="width: 600px;">
            <h3 class="card_header">{{ card.header }}</h3>
            <p class="card_description">{{ card.description }}</p>
            <a :href="card.link" class="learn_more">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'SmartProduct',
  setup() {
    const cards = ref([
      {
        header: 'Easy-to-Use Interface',
        description: 'Our audio-visual solutions are easy to use, with a simple interface for switching inputs, adjusting volume, and controlling features. Perfect for presentations, meetings, and multimedia.',
        link: '/solutions/?label=Smart%20Classroom%20Solution'
      },
      {
        header: 'Top Quality AV',
        description: 'Enjoy clear audio and stunning visuals with our top-notch audio-visual solutions. Get sharp, vibrant images and immersive sound, perfect for presentations, meetings, and entertainment.',
        link: '/solutions/?label=Audio%20Visual'
      },
      {
        header: 'Maintenance Support',
        description: 'Enjoy clear audio and stunning visuals with our top-notch audio-visual solutions. Get sharp, vibrant images and immersive sound, perfect for presentations, meetings, and entertainment.',
        link: '/solutions/?label=Smart%20Conference'
      }
    ]);

    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger);

      const cardsEl = document.querySelectorAll('.smart-card');
      //const headerEl = document.querySelector('.header');
      const animation = gsap.timeline();
      let cardHeight;

      function initCards() {
        animation.clear();
        cardHeight = cardsEl[0].offsetHeight;
        console.log("Card Height:", cardHeight);
        console.log("Total Scroll Height:", cardsEl.length * cardHeight);
        cardsEl.forEach((card, index) => {
          if (index > 0) {
            gsap.set(card, {y: index * cardHeight});
            animation.to(card, {y: 0, duration: index * 0.2, ease: "none"}, 0);
          }
        });
      }


      initCards();

      ScrollTrigger.create({
        trigger: ".wrapper",
        start: "top top",
        pin: true,
        end: () => `+=${(cardsEl.length) * cardHeight}`, // Adjusted to subtract one card height
        scrub: true,
        animation: animation,
        markers: false,
        invalidateOnRefresh: true
      });


      ScrollTrigger.addEventListener("refreshInit", initCards);

    });

    return {
      cards
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Teko');

* {
  position: relative;
  box-sizing: border-box;
}

.wrapper {
  height: 100vh; /* Ensure this does not go beyond viewport height */
  overflow: hidden;
}

.next-section {
  margin-top: 0; /* Ensure the next section starts immediately after the scrolling section */
  padding-top: 20px; /* Add padding if needed */
}

.cards {
  width: 100%;
  height: 83%;
  position: relative;
}

.smart-card {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1565C0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header h2 {
  width: 100%;
  text-align: center;
  font-size: 48px;
  line-height: 1;

}

.header {
  margin-bottom: 40px;
}


.smart-card .image-1 {
  background-image: linear-gradient(98deg, rgba(30, 30, 30, 0.90) 0.27%, rgba(50, 50, 50, 0.00) 100%), url(./Artives-smart-product/Rectangle\ 4253.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  align-content: center;
  text-wrap: wrap;
  padding-right: 10rem;
}

.smart-card .image-2 {
  background-image: linear-gradient(98deg, rgba(30, 30, 30, 0.90) 0.27%, rgba(50, 50, 50, 0.00) 100%), url(./Artives-smart-product/Rectangle\ 4253\ \(1\).png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  align-content: center;
  text-wrap: wrap;
  padding-right: 10rem;
}

.smart-card .image-3 {
  background-image: linear-gradient(98deg, rgba(30, 30, 30, 0.90) 0.27%, rgba(50, 50, 50, 0.00) 100%), url(./Artives-smart-product/Rectangle\ 4253\ \(2\).png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  align-content: center;
  text-wrap: wrap;
  padding-right: 10rem;
}

.card_header {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
  margin-bottom: 10px;
}

.card_description {
  color: #FFF;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 25.2px */
  margin-bottom: 40px;
}

.learn_more {
  color: #FFF;
  text-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 34px;
  border: 1px solid #FFF;
  padding: 12px 24px;
  text-decoration: none;
  position: absolute;
  text-transform: capitalize;
}
@media only screen and (max-width: 576px) {
  .header h2 {
    font-size: 24px;
  }
  .header {
    margin-bottom: 20px;
  }
  .smart_product_text{
    width: 300px !important;
  }
  .card_header{
    font-size: 24px;
  }
  .learn_more{
    padding: 8px 20px;
  }
  .cards {
    height: 90%;
  }
  .header h2 {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1;

}
}


@media only screen and (min-width: 577px) and (max-width: 991px) {
  .cards {
    height: 40vh;
  }

  .smart-card .image > svg {
    width: 70px;
    height: 70px;
  }

  .card_header {
    color: #FFF;
    font-size: 30px;
  }

  .card_description {
    color: #FFF;
    text-align: justify;
    font-size: 12px;
  }

  .learn_more {
    font-size: 12px;
    padding: 8px 24px;

  }


  .header h2 {
  width: 100%;
  text-align: center;
  font-size: 35px;
  line-height: 1;

}
}
</style>