<template>
  <div class="Artiv position-sticky top-0 " style="z-index: 3">
    <header>
      <!--------------  Main NAV  --------------------->
      <nav class="navbar navbar-expand-lg Artive-nav-hero ">
        <div class="container-fluid  w-100 ">
          <a class="navbar-brand" href="/">
            <img src="./Artives-nav-image/artive-nav-logo.svg" alt="">
          </a>

          <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasNavbar"
               aria-labelledby="offcanvasWithBothOptionsLabel">

            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                      aria-label="Close"></button>
            </div>

            <div class="offcanvas-body align-items-center justify-content-between d-none d-lg-flex">
              <ul
                  class="navbar-nav d-flex justify-content-between align-items-center mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link text-white" aria-current="page" href="/">HOME</a>
              </li>
              <li class="nav-item dropdown text-white">
                <a class="nav-link dropdown-toggle text-white" :href="`/products/${firstCategory}/#product-categories`"
                   role="button"
                   data-bs-toggle="dropdown" aria-expanded="false" @click="navigateToCategory(this.firstCategory)"
                   @mouseenter="showDropdown" @mouseleave="hideDropdown">
                  PRODUCT
                </a>
                <div class="dropdown-menu Dropdown_menu" :class="{ 'show': productDropdownVisible }"
                     @mouseenter="showDropdown" @mouseleave="hideDropdown">
                  <div class="d-flex align-items-start drop_down_area">
                    <div class="vartical_nav nav flex-column nav-pills me-3" id="v-pills-tab1"
                         role="tablist" aria-orientation="vertical">
                      <div class="w-100">
                        <button v-for="(category, index) in categoriesData" :key="index"
                                class="nav-link"
                                :class="{ 'active': activeTab === index }"
                                @mouseenter="setActiveTab(index)"
                                @mouseleave="resetActiveTab">
                          {{ category.title }}
                        </button>
                      </div>
                      <div class="mt-auto">
                        <button class="nav-link" id="v-pills-product" data-bs-toggle="pill"
                                @click="showAllProducts(categoriesData[0].title)" type="button" role="tab"
                                aria-controls="v-pills-product-tab" aria-selected="false">
                          All Products
                        </button>
                      </div>
                    </div>

                    <div class="tab-content hide_scroll overflow-scroll w-100" id="v-pills-tab2" style="padding: 0; height: 75vh">
                      <div v-for="(category, index) in categoriesData" :key="index"
                           class="tab-pane fade"
                           :class="{ 'show active': activeTab === index }">
                        <div class="tap_pane">
                          <div class="d-flex flex-column align-items-center product"
                               v-for="product in filteredProducts(category.id)" :key="product.id">
                            <a :href="`/product/${product.slug}`">
                              <img :src="product.image" alt="" style="margin-bottom: 1rem;">
                              <div class="item_name">
                                <h2>{{ product.title }}</h2>
                                <h2>{{ product.type }}</h2>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown text-white">
                <a class="nav-link active dropdown-toggle text-white" href="#" role="button"
                   data-bs-toggle="dropdown" aria-expanded="false" @mouseenter="showSolutionDropdown" @mouseleave="hideSolutionDropdown">
                  SOLUTION
                </a>
                <div class="dropdown-menu Dropdown_menu" :class="{ 'show': solutionDropdownVisible }" @mouseenter="showSolutionDropdown" @mouseleave="hideSolutionDropdown">
                  <div class="d-flex align-items-start drop_down_area">
                    <div class="nav vartical_nav flex-column nav-pills me-3" id="v-pills-tab3" role="tablist"
                         aria-orientation="vertical">
                      <button v-for="(mainTab, mainIndex) in mainTabs" :key="mainIndex"
                              class="nav-link"
                              :class="{ 'active': activeMainTab === mainIndex }"
                              @click="mainTab.label !== 'Smart Education' && navigateToSolution(mainTab.label)"
                              @mouseenter="setActiveMainTab(mainIndex)"
                              @mouseleave="resetActiveMainTab">
                        {{ mainTab.label }}
                      </button>
                    </div>
                    <div class="tab-content" id="v-pills-tabContent2">
                      <div v-for="(mainTab, mainIndex) in mainTabs" :key="mainIndex"
                           class="tab-pane fade"
                           :class="{ 'show active': activeMainTab === mainIndex }">
                        <!-- Check if mainTab has subTabs -->
                        <div v-if="mainTab.subTabs && mainTab.subTabs.length > 0" class="d-flex align-items-start">
                          <div class="nav vartical_nav flex-column nav-pills me-3" id="v-pills-tab4" role="tablist"
                               aria-orientation="vertical">
                            <button v-for="(subTab, subIndex) in mainTab.subTabs" :key="subIndex"
                                    class="nav-link"
                                    :class="{ 'active': activeSubTab === subIndex && activeMainTab === mainIndex }"
                                    @click="navigateToSolution(subTab.label)"
                                    @mouseenter="setActiveSubTab(subIndex)"
                                    @mouseleave="resetActiveSubTab">
                              {{ subTab.label }}
                            </button>
                          </div>
                          <div class="tab-content" id="v-pills-tabContent3">
                            <div v-for="(subTab, subIndex) in mainTab.subTabs" :key="subIndex"
                                 class="tab-pane fade"
                                 :class="{ 'show active': activeSubTab === subIndex && activeMainTab === mainIndex }">
                              <div class="classroom_area w-100" style="padding: 0px; margin-top: 1.25rem;">
                                <div @click="navigateToSolution(subTab.label)" style="cursor: pointer;">
                                  <img :src="subTab.image" class="w-100 h-100 mb-4" alt="">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div @click="navigateToSolution(mainTab.label)">
                            <img :src="mainTab.image" class=" h-100 mb-4" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link text-white text-nowrap" href="/about-us/">ABOUT US</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-white" href="/contact-us/">CONTACT</a>
              </li>
              <li class="nav-item search_area">
                <div class="nav-link">
                  <div class="dropdown-center">
                    <button type="button" class="btn dropdown-toggle nav-search" data-bs-toggle="dropdown"
                            data-bs-display="static" aria-expanded="false" @click="toggleSearch">
                      <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.6836 21L16.8503 16.1667M19.4614 9.88889C19.4614 14.7981 15.4817 18.7778 10.5725 18.7778C5.66328 18.7778 1.68359 14.7981 1.68359 9.88889C1.68359 4.97969 5.66328 1 10.5725 1C15.4817 1 19.4614 4.97969 19.4614 9.88889Z"
                            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex align-items-center w-100">
                            <div class="search-icon" v-if="searchActive">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                   fill="none">
                                <path
                                    d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
                                    stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                            <input class="search-box" type="text" v-model="searchString"
                                   :class="{ active: searchActive }"
                                   ref="searchBox" placeholder="search here.." @focus="searchActive = true" style="width: 113%; min-width: 300px;"/>
                            <div class="close-search" v-if="searchActive" @click="closeSearch">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12"
                                   fill="none">
                                <path
                                    d="M7.16148 6.00041L10.6965 2.46441C10.792 2.37216 10.8682 2.26181 10.9206 2.13981C10.973 2.01781 11.0006 1.88659 11.0017 1.75381C11.0029 1.62103 10.9776 1.48935 10.9273 1.36645C10.877 1.24356 10.8028 1.1319 10.7089 1.03801C10.615 0.944118 10.5033 0.869865 10.3804 0.819584C10.2575 0.769303 10.1259 0.744001 9.99307 0.745155C9.8603 0.746309 9.72908 0.773895 9.60707 0.826304C9.48507 0.878713 9.37472 0.954895 9.28248 1.05041L5.74648 4.58541L2.21148 1.05041C2.11923 0.954895 2.00888 0.878713 1.88688 0.826304C1.76488 0.773895 1.63366 0.746309 1.50088 0.745155C1.3681 0.744001 1.23642 0.769303 1.11352 0.819584C0.990625 0.869865 0.878973 0.944118 0.785081 1.03801C0.691188 1.1319 0.616935 1.24356 0.566654 1.36645C0.516373 1.48935 0.491071 1.62103 0.492225 1.75381C0.493379 1.88659 0.520965 2.01781 0.573374 2.13981C0.625783 2.26181 0.701966 2.37216 0.797476 2.46441L4.33248 5.99941L0.797476 9.53541C0.701966 9.62765 0.625783 9.738 0.573374 9.86C0.520965 9.98201 0.493379 10.1132 0.492225 10.246C0.491071 10.3788 0.516373 10.5105 0.566654 10.6334C0.616935 10.7563 0.691188 10.8679 0.785081 10.9618C0.878973 11.0557 0.990625 11.1299 1.11352 11.1802C1.23642 11.2305 1.3681 11.2558 1.50088 11.2547C1.63366 11.2535 1.76488 11.2259 1.88688 11.1735C2.00888 11.1211 2.11923 11.0449 2.21148 10.9494L5.74648 7.41441L9.28248 10.9494C9.37472 11.0449 9.48507 11.1211 9.60707 11.1735C9.72908 11.2259 9.8603 11.2535 9.99307 11.2547C10.1259 11.2558 10.2575 11.2305 10.3804 11.1802C10.5033 11.1299 10.615 11.0557 10.7089 10.9618C10.8028 10.8679 10.877 10.7563 10.9273 10.6334C10.9776 10.5105 11.0029 10.3788 11.0017 10.246C11.0006 10.1132 10.973 9.98201 10.9206 9.86C10.8682 9.738 10.792 9.62765 10.6965 9.53541L7.16148 6.00041Z"
                                    fill="#848E99"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div class="suggestion-div " v-if="!searchString">
                        <ul class="list-group">
                          <li class="list-group-item mt-2" v-for="result in results" :key="result.id"
                              @mousedown.prevent="selectResult(result)" @click.stop="keepSearchActive">+ {{
                              result.label
                            }}
                          </li>
                        </ul>
                      </div>
                      <div class="show-products d-flex align-items-center"
                            v-if="searchString && fetchedProducts.length > 0">
                        <ul class="list-group">
                          <li class="list-group-item mt-2 border-bottom cursor-pointer" v-for="product in fetchedProducts"
                              :key="product.id"
                              @click="selectResult(product)">
                            <a href="`/product/${product.slug}`">
                              <img :src="product.image" alt="no image found">
                              <div class="text-black">{{ product.title }}</div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div v-if="searchString && fetchedProducts.length === 0">
                        No product found
                      </div>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <a href="/contact-us/">
              <button class="get_btn text-nowrap ">GET IN TOUCH
                <img src="./Artives-nav-image/button-arrow.png" alt="">
              </button>
            </a>
          </div>

          <ul class="navbar-mobile d-block d-lg-none overflow-scroll">
            <li class="home main-nav">
              <div class="ps-2"><a href="/" class="text-decoration-none" style="color: #1E1E1E;">Home</a></div>
            </li>
            <li class="products main-nav" @click="toggleMainDropdown('productsOpen')">
              <div class="px-2 pb-2 d-flex justify-content-between">
                <div>Products</div>
                <div >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                    <path d="M11 1.5L6 6.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <transition name="fade-slide">
                <ul v-if="productsOpen" class="category-list overflow-y-scroll">
                  <li v-for="category in categoriesData" :key="category.id" class="category"
                      @click.stop="toggleDropdown(category.id)">
                    <div class="px-2 pb-2 d-flex justify-content-between">
                      <div>{{ category.title }}</div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                          <path d="M11 1.5L6 6.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                    <transition name="fade-slide">
                      <ul v-if="categoryOpen[category.id]" class="product-list">
                        <li v-for="product in filteredProducts(category.id)" :key="product.id">
                          <a :href="`/product/${product.slug}`">{{ product.title }} ({{ product.type }})</a>
                        </li>
                      </ul>
                    </transition>
                  </li>
                  <li><a @click="showAllProducts(categoriesData[0].title)" style="color: #1E1E1E">All Products</a></li>
                </ul>

              </transition>
            </li>
            <li class="solutions main-nav" @click="toggleMainDropdown('solutionsOpen')">
              <div class="px-2 pb-2 d-flex justify-content-between">
                <div>Solutions</div>
                <div >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                    <path d="M11 1.5L6 6.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <transition name="fade-slide">
                <ul v-if="solutionsOpen" class="solution-list">
                  <li class="solution" @click.stop="toggleMainDropdown('smartOpen')">
                    <div>Smart Education</div>
                    <transition name="fade-slide">
                      <ul v-if="smartOpen" class="smart-education-list">
                        <li class="smart-education" @click="navigateToSolution('Smart Classroom Solution')">
                          Smart Classroom Solution
                        </li>
                        <li class="smart-education" @click="navigateToSolution('Hybrid Classroom Solution')">
                          Hybrid Classroom Solution
                        </li>
                        <li class="smart-education" @click="navigateToSolution('Paperless Classroom Solution')">
                          Paperless Classroom Solution
                        </li>
                      </ul>
                    </transition>
                  </li>
                  <li class="solution" @click="navigateToSolution('Audio Visual')">
                    Audio Visual
                  </li>
                  <li class="solution" @click="navigateToSolution('Smart Conference')">
                    Smart Conference
                  </li>
                </ul>
              </transition>
            </li>
            <li class="about-us main-nav">
              <div class="ps-2"><a class="text-decoration-none" href="/about-us/" style="color: #1E1E1E">About Us</a>
              </div>
            </li>
            <li class="contact main-nav">
              <div class="ps-2"><a class="text-decoration-none" href="/contact-us/" style="color: #1E1E1E">Contact
                Us</a></div>
            </li>
          </ul>
          </div>
          <div class="d-flex gap-3">
            <div class="nav-item search_area d-block d-lg-none mobile_search">
              <div class="nav-link">
                <div class="dropdown-center">
                  <button type="button" class="btn dropdown-toggle nav-search" data-bs-toggle="dropdown"
                          data-bs-display="static" aria-expanded="false" @click="toggleSearch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                          d="M17 17L13.1333 13.1333M15.2222 8.11111C15.2222 12.0385 12.0385 15.2222 8.11111 15.2222C4.18375 15.2222 1 12.0385 1 8.11111C1 4.18375 4.18375 1 8.11111 1C12.0385 1 15.2222 4.18375 15.2222 8.11111Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <div class="d-flex align-items-center">
                        <div class="p-2 d-flex align-items-center w-100">
                          <div class="search-icon" v-if="searchActive">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                 fill="none">
                              <path
                                  d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
                                  stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                          <input class="search-box" type="text" v-model="searchString"
                                 :class="{ active: searchActive }"
                                 ref="searchBox" placeholder="search here.." @focus="searchActive = true" style="width: 113%;"/>
                          <div class="close-search" v-if="searchActive" @click="closeSearch">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12"
                                 fill="none">
                              <path
                                  d="M7.16148 6.00041L10.6965 2.46441C10.792 2.37216 10.8682 2.26181 10.9206 2.13981C10.973 2.01781 11.0006 1.88659 11.0017 1.75381C11.0029 1.62103 10.9776 1.48935 10.9273 1.36645C10.877 1.24356 10.8028 1.1319 10.7089 1.03801C10.615 0.944118 10.5033 0.869865 10.3804 0.819584C10.2575 0.769303 10.1259 0.744001 9.99307 0.745155C9.8603 0.746309 9.72908 0.773895 9.60707 0.826304C9.48507 0.878713 9.37472 0.954895 9.28248 1.05041L5.74648 4.58541L2.21148 1.05041C2.11923 0.954895 2.00888 0.878713 1.88688 0.826304C1.76488 0.773895 1.63366 0.746309 1.50088 0.745155C1.3681 0.744001 1.23642 0.769303 1.11352 0.819584C0.990625 0.869865 0.878973 0.944118 0.785081 1.03801C0.691188 1.1319 0.616935 1.24356 0.566654 1.36645C0.516373 1.48935 0.491071 1.62103 0.492225 1.75381C0.493379 1.88659 0.520965 2.01781 0.573374 2.13981C0.625783 2.26181 0.701966 2.37216 0.797476 2.46441L4.33248 5.99941L0.797476 9.53541C0.701966 9.62765 0.625783 9.738 0.573374 9.86C0.520965 9.98201 0.493379 10.1132 0.492225 10.246C0.491071 10.3788 0.516373 10.5105 0.566654 10.6334C0.616935 10.7563 0.691188 10.8679 0.785081 10.9618C0.878973 11.0557 0.990625 11.1299 1.11352 11.1802C1.23642 11.2305 1.3681 11.2558 1.50088 11.2547C1.63366 11.2535 1.76488 11.2259 1.88688 11.1735C2.00888 11.1211 2.11923 11.0449 2.21148 10.9494L5.74648 7.41441L9.28248 10.9494C9.37472 11.0449 9.48507 11.1211 9.60707 11.1735C9.72908 11.2259 9.8603 11.2535 9.99307 11.2547C10.1259 11.2558 10.2575 11.2305 10.3804 11.1802C10.5033 11.1299 10.615 11.0557 10.7089 10.9618C10.8028 10.8679 10.877 10.7563 10.9273 10.6334C10.9776 10.5105 11.0029 10.3788 11.0017 10.246C11.0006 10.1132 10.973 9.98201 10.9206 9.86C10.8682 9.738 10.792 9.62765 10.6965 9.53541L7.16148 6.00041Z"
                                  fill="#848E99"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </li>
                    <div class="suggestion-div " v-if="!searchString">
                      <ul class="list-group">
                        <li class="list-group-item mt-2" v-for="result in results" :key="result.id"
                            @mousedown.prevent="selectResult(result)" @click.stop="keepSearchActive">+ {{
                            result.label
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="show-products d-flex align-items-center"
                         v-if="searchString && fetchedProducts.length > 0">
                      <ul class="list-group">
                        <li class="list-group-item mt-2 border-bottom cursor-pointer" v-for="product in fetchedProducts"
                            :key="product.id"
                            @click="selectResult(product)">
                          <a href="`/product/${product.slug}`">
                            <img :src="product.image" alt="no image found">
                            <div class="text-black">{{ product.title }}</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="searchString && fetchedProducts.length === 0">
                      No product found
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <button class="navbar-toggler text-white" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation">
                        <span class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12"
                                 fill="none">
                                <path d="M0.792969 6.08368H18.2103M0.792969 1H18.2103M0.792969 11H18.2103" stroke="white" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
            </button>
          </div>
      </div>
    </nav>
  </header>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ArtivesNav",
  props: ['products', 'categories'],
  data() {
    const categories = JSON.parse(this.categories);
    return {
      productsOpen: false,
      solutionsOpen: false,
      smartOpen: false,
      categoryOpen: {},
      suggestionsActive: false,
      searchActive: false,
      searchString: "",
      productDropdownVisible: false,
      solutionDropdownVisible: false,
      productsData: JSON.parse(this.products),
      categoriesData: categories,
      firstCategory: categories.length > 0 ? categories[0].title : "",
      results: [
        {id: '1', label: 'Smart', image: ''},
        {id: '2', label: 'Panel', image: ''},
        {id: '1', label: 'Projector', image: ''},
        {id: '1', label: 'Hybrid', image: ''}
      ],
      activeTab: 0,
      activeMainTab: 0,
      activeSubTab: 0,
      mainTabs: [
        {
          label: 'Smart Education',
          subTabs: [
            {
              label: 'Smart Classroom Solution',
              image: 'https://artive.com.bd/media/images/Navication_smart_classroom.webp'
            },
            {
              label: 'Hybrid Classroom Solution',
              image: 'https://artive.com.bd/media/images/Navication_hybrid.webp'
            },
            {
              label: 'Paperless Classroom Solution',
              image: 'https://artive.com.bd/media/images/Navication_Paperless.webp'
            },
          ]
        },
        {
          label: 'Audio Visual',
          image: 'https://artive.com.bd/media/images/Navication_Audio_Visual.webp'
        },
        {
          label: 'Smart Conference',
          image: 'https://artive.com.bd/media/images/Navication_Smart_Conference.webp'
        },
      ],
      debounceTimeout: null,
      fetchedProducts: [],
    }
  },
  mounted() {
    // this.printdata();
  },
  methods: {
    navigateToCategory(firstCategory) {
      window.location.href = `/products/${firstCategory}/#product-categories`;
    },
    showSolutionDropdown() {
      this.solutionDropdownVisible = true;
    },
    hideSolutionDropdown() {
      this.solutionDropdownVisible = false;
    },
    showDropdown() {
      this.productDropdownVisible = true;
    },
    hideDropdown() {
      this.productDropdownVisible = false;
    },
    printdata() {
      console.log(this.products);
      console.log(this.categories);
    },
    toggleMainDropdown(dropdown) {
      this[dropdown] = !this[dropdown];
    },
    toggleDropdown(categoryId) {
      if (!(categoryId in this.categoryOpen)) {
        this.categoryOpen[categoryId] = false;
      }
      this.categoryOpen[categoryId] = !this.categoryOpen[categoryId];
    },
    isOpen(categoryId) {
      return this.categoryOpen[categoryId];
    },
    showCategory(category) {
      this.activeCategory = category;
      // console.log('Selected Category:', category);
    },
    showSolution(solution) {
      this.activeSolution = solution;
      // console.log('Selected Solution:', solution);
    },
    setActiveTab(index) {
      this.activeTab = index; // Set the active tab on hover
    },
    resetActiveTab() {
      // this.activeTab = null;
    },
    setActiveMainTab(index) {
      this.activeMainTab = index;
      this.activeSubTab = null;
    },
    resetActiveMainTab() {
      //this.activeMainTab = null;
    },
    setActiveSubTab(index) {
      this.activeSubTab = index;
    },
    resetActiveSubTab() {
      //this.activeSubTab = null;
    },
    filteredProducts(categoryId) {
      return this.productsData.filter(product => product.category === categoryId);
    },
    keepSearchActive() {
      this.searchActive = true;
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;

      if (this.searchActive) {
        this.$nextTick(() => {
          this.$refs.searchBox.focus();
        });
      } else {
        this.searchString = '';
      }
    },
    closeSearch() {
      this.searchActive = false;
      this.searchString = '';
      this.suggestionsActive = false;
    },
    selectResult(result) {
      this.searchString = result.label;
      this.fetchProducts();
      this.searchActive = true;
    },
    fetchProducts() {
      if (!this.searchString) {
        this.fetchedProducts = [];
        return;
      }

      axios.get('/fetch-search-products/', {
        params: {
          search: this.searchString
        }
      })
          .then(response => {
            this.fetchedProducts = response.data.products;
          })
          .catch(error => {
            console.error("There was an error fetching the products:", error);
          });
    },
    handleInputChange() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.fetchProducts();
      }, 300);
    },
    navigateToSolution(label) {
      // Use window.location to navigate, or use router if available
      window.location.href = `/solutions/?label=${encodeURIComponent(label)}`;
    },
    showAllProducts(firstCategory) {
      window.location.href = `/products/${firstCategory}/#product-categories`;
    }
  },
  watch: {
    searchString() {
      this.handleInputChange();
    },
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.dropdown-menu.show {
  overflow-y: scroll;

}

.hide_scroll::-webkit-scrollbar {
  display: none;
}


.hide_scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: max-height 0.3s linear, opacity 0.3s linear;
}

.fade-slide-enter,
.fade-slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.fade-slide-enter-to,
.fade-slide-leave {
  max-height: 500px;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 2px !important;
}


::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c6c6c6;
}
.tab-pane.fade.show.active img {
  margin-top: 1rem;
}

.tab-pane.fade.show.active img {
  border-radius: 5px;
}

.classroom_area {
  text-align: center;
}

.see-solution.btn {
  border: 1px solid #1E1E1E;
  border-radius: 5px;
  padding: 12px 8px;
}

.see-solution.btn:hover {
  background: #1E1E1E;
  color: white;
}
.offcanvas-header {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.offcanvas-header .btn-close {
  margin: 0;
  padding: 1.5rem;
}

.navbar-mobile .main-nav {
  padding: 1rem 1.563rem;
  list-style-type: none;
  border-bottom: 1px solid #DBDFD0;
  cursor: pointer;
}

.navbar-mobile li {
  list-style-type: none;
}

.category-list, .solution-list {
  background: #f2f2f2;
  cursor: pointer;
}

.category-list li {
  padding: 1rem 2rem;
  list-style-type: none;
}

.solution-list li {
  padding: 1rem 2rem;
}

.smart-education-list li {
  padding: 1rem 2.5rem;
  list-style-type: none;
}

.mobile_search.search_area .dropdown-menu{
  top: 3rem !important;
  left: -13rem !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item {
  border: none;
  cursor: pointer;
}
.mobile_search.search_area .dropdown-menu .list-group-item a{
  padding: 0 !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item a img {
  max-width: 13.75rem;
}
.search-box {
  padding: 0.75rem 1.25rem 0.75rem 2.25rem;
  border: 1px solid transparent;
  background: #e5e5e5;
  border-radius: 5px;
}

.search-box:focus {
  outline: 0;
}

.search-icon {
  margin-right: -2rem;
  z-index: 1001;
}

.close-search {
  margin-left: -2rem;
}

.Dropdown_menu {
  left: 0 !important;
}
.dropdown-menu {
  margin-top: 0 !important;
}

.search_area .dropdown-menu {
  position: absolute !important;
  top: 3.8rem !important;
  left: -7rem !important;
  padding: 1rem 2rem;
  transform: none !important; /* Prevents Bootstrap's auto positioning */
  will-change: transform; /* Helps maintain the dropdown position */
}

.Artive-nav-hero {
  padding: 0px 80px;
  background: #1E1E1E;
}

/* Show the tab content when the dropdown menu is hovered */

.get_btn {
  border-radius: 8px;
  background: linear-gradient(76deg, #1AE5FE 19.75%, #00B9D0 80.25%);
  box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.08);
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav {
  gap: 50px;
}

.navbar-nav li {
  position: static;
  background: transparent;
  border: none;
  outline: none;
}

.navbar-nav li a::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 0.175rem;
  background: #1AE5FE;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.navbar-nav li a:hover::before {
  transform: scale(1, 1);
}


.navbar-nav a {
  color: #FFF;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: uppercase;
  padding: 40px 20px;
}

.navbar-toggler {
  background: transparent;
  box-shadow: none !important;
  border: none !important;
  outline: none;
}
.nav-link{
  position: relative;
}

/*.dropdown-toggle::after {*/
/*  display: inline-block;*/
/*  position: absolute;*/
/*  top: 52px;*/
/*  right: -5px;*/
/*  margin-left: .255em;*/
/*  vertical-align: .255em;*/
/*  content: "";*/
/*  border-top: .3em solid;*/
/*  border-right: .3em solid transparent;*/
/*  border-bottom: 0;*/
/*  border-left: .3em solid transparent;*/
/*  transform: rotate(-50deg);*/
/*}*/

.get_btn img {
  left: 6%;
  top: -7%;
  animation: spin 3s infinite;
}

@keyframes spin {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(0.7rem);
  }
}


.vartical_nav {
  background: #EAEAEA;
  width: fit-content;
  padding: 40px 0px;
  min-width: 250px;
  height: 75vh;
}

.drop_down_area {
  background: white;
  gap: 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1E1E1E;
  background: #F4F4F4;
}

.nav-pills .nav-link {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
  padding: 15px 20px;
  letter-spacing: 0.07px;
  text-align: start;
  width: 100%;
}

.item_name h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
  /* 17.92px */
  letter-spacing: 0.42px;
  margin-bottom: 0;
}

.tap_pane {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.Dropdown_menu {
  top: 100%;
  width: -webkit-fill-available;
  margin: 0 5rem;
  height: 75vh;
}

@media only screen and (max-width: 576px) {
  .navbar-brand img {
    width: 78px;
    height: auto;
  }
}

@media only screen and  (max-width: 991px) {
  .Artive-nav-hero {
    padding: 15px 20px;
  }

  .main_head {
    font-size: 30px;

  }

  .description {
    font-size: 12px;
    line-height: 20px;
  }

  .navbar-nav {
    flex-direction: column;
    gap: 20px;

  }

  .get_btn {
    width: 100%;
  }

  .nav-item a {
    color: black !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding: 30px 20px !important;
  }

  .dropdown-toggle::after {
    top: 42px;
    right: 0px;
  }

  .search_area {
    display: none;
  }



}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .Artive-nav-hero {
    padding: 0px 20px;
  }

  .dropdown-toggle::after {
    top: 52px;
    right: -4px;
  }



  .drop_down_area {
    background: white;
    gap: 0px;
  }

  .Dropdown_menu {
    top: 100%;
  }
  .navbar-nav{
    gap: 26px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1400px) {
    .tap_pane {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
}
</style>