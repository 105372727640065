<template>
  <div class="w-100 part_two_bottom bg-white" :id="this.id">
    <div class="d-flex flex-column-reverse flex-lg-row align-items-center bg-white">
      <div class="w-100 w-lg-50 part_two_bottom_text ps-0" style="padding-right: 5rem;">
        <h2>{{ contentData.title }}</h2>
        <p>{{ contentData.description }}</p>
        <div class="d-flex" style="gap: 3rem;">
          <img v-for="(icon, index) in contentData.icons" :key="index" :src="icon.src" :alt="icon.alt">
        </div>
      </div>
      <div class="w-100 w-lg-50">
        <img class="w-100" :src="contentData.image.src" :alt="contentData.image.alt">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSubfour",
  props: {
    content: {
      type: String,
      default: JSON.stringify({
        title: "Scratch-Resistant & Anti-Glare Glass",
        description: "The panel's glass has a hardness rating of Mohs 7, which means it is highly resistant to scratches and damage. This level of hardness ensures that the screen remains durable and protected from everyday wear and tear. Additionally, the anti-glare coating reduces reflections and improves visibility, making the screen easier to read and use in various lighting conditions. This combination provides both durability and a clearer, more comfortable viewing experience.",
        icons: [
          {
            src: "https://artive.com.bd/media/images/icon1.webp",
            alt: "Icon One"
          },
          {
            src: "https://artive.com.bd/media/images/icon2.webp",
            alt: "Icon Two"
          }
        ],
        image: {
          src: "./Artives-Interactive-Details-image/Frame 1000001770 (2).png",
          alt: "Scratch-Resistant & Anti-Glare Glass"
        }
      })
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    }
  }
}
</script>

<style scoped>
.part_two_bottom {
  padding: 0 60px;
}

.part_two_bottom_text {
  padding-left: 80px;
}
.part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.part_two_bottom_text p {
  color:  #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}
@media only screen and (max-width: 576px) {
  .part_two_bottom {
    padding: 20px;

  }

  .part_two_bottom_text {
    margin: 0;
    padding: 0 !important;
    margin-top: 20px;
  }
  .part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .part_two_bottom {
    padding: 20px;

  }

  .part_two_bottom_text {
    padding: 0 !important;
    margin: 0;
    margin-top: 30px;
  }

}
</style>